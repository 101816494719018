import { Enum, Enums } from "@models/enums/enum.type";
import { WorkLocation } from "@core/api/api-client";

export const WorkLocations = new Enums([
  new Enum({
    key: WorkLocation.RemotelyOnly,
    name: "Remotely Only",
    route: ["remotely"],
    color: "cyan-500",
  }),
  new Enum({
    key: WorkLocation.OfficeOnly,
    name: "Office Only",
    route: ["office"],
    color: "cyan-500",
  }),
  new Enum({
    key: WorkLocation.Both,
    name: "Both",
    route: ["both"],
    color: "cyan-500",
  }),
]);
