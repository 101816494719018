import {
  toLanguage,
  getLangDir,
  isLangRoutePrefix,
  langToRoute,
} from "@core/services/settings/utils";
import { mergeMap } from "rxjs/operators";
import { SETTINGS_TOKEN_KEY } from "@core/tokens-key";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IAppSettings, Lang, LangRoute } from "./settings.types";
import { Default } from "./default-settings";
import { APP_BASE_HREF, DOCUMENT, Location } from "@angular/common";
import { isEmptyObject } from "@core/utils";
import { AccountsClient } from "@core/api/api-client";
import { IdentityManager } from "@core/auth/services/identity-manager.service";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import { Directionality } from "@angular/cdk/bidi";
import { AppDirectionality } from "./directionality.service";

@Injectable()
export class SettingsService {
  private _options: IAppSettings;
  private _notify$ = new BehaviorSubject<Partial<IAppSettings>>({});

  constructor(
    private readonly _location: Location,
    private readonly _identityManager: IdentityManager,
    private readonly _store: LocalStorageService,
    private readonly _accountsClient: AccountsClient,
    @Inject(DOCUMENT) private readonly _dom: Document,
    @Inject(APP_BASE_HREF) private readonly _baseHref: LangRoute,
    @Inject(Directionality) public readonly _dir: AppDirectionality
  ) {
    this._options = {
      ...Default,
      ...(this._store.get(SETTINGS_TOKEN_KEY) as IAppSettings),
    };
  }

  /** Options changes observable */
  get notify$(): Observable<Partial<IAppSettings>> {
    return this._notify$.asObservable();
  }

  /** Get current options */
  get options(): IAppSettings {
    return { ...this._options };
  }

  get language(): Lang {
    return this._options.language;
  }

  /* Set system language */
  set language(lang: Lang) {
    this.changeOptions({ language: lang, dir: getLangDir(lang) });
    this.setUserLanguage(lang);
    // should be last to can set user preferences
    this._correctLangRoute(lang);
  }

  /** Merge changes with the current options */
  changeOptions(changes: Partial<IAppSettings>): void {
    if (isEmptyObject(changes)) {
      return;
    }

    this._notify$.next(changes);
    this._options = { ...this._options, ...changes };
    this._store.set(SETTINGS_TOKEN_KEY, this._options);
  }

  changeDirectionality(lang: Lang): void {
    const dir = getLangDir(lang);

    // html dir & lang
    const htmlTag = this._dom.getElementsByTagName("html")[0];
    htmlTag.setAttribute("lang", lang);
    htmlTag.setAttribute("dir", dir);

    const bodyTag = this._dom.getElementsByTagName("body")[0];
    bodyTag.setAttribute("dir", dir);

    // material bidi
    this._dir.value = dir;
  }

  setUserLanguage(lang: Lang): void {
    const user = this._identityManager.user;
    if (user?.id && user.haveAccount) {
      this._accountsClient
        .setLanguage({ lang: toLanguage(lang) })
        .pipe(mergeMap(() => this._identityManager.refreshToken()))
        .subscribe();
    }
  }

  /** Correct current route with @lang */
  private _correctLangRoute(lang: Lang): void {
    const hrefPrefix = this._baseHref;
    const correctPrefix = langToRoute(lang);

    // - already matches
    if (correctPrefix === hrefPrefix) {
      return;
    }

    const currPath = this._location.path(true);

    if (!currPath) {
      location.pathname = `/${correctPrefix}`;
      return;
    }

    const currPrefix = currPath.substr(1, 2);

    location.pathname = isLangRoutePrefix(currPrefix)
      ? `/${correctPrefix}${currPath.substr(3)}`
      : `/${correctPrefix}${currPath}`;

    // TODO: can enhance using window.history to avoid reload and lose current state.
  }
}
