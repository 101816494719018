import { Enum, Enums } from "@models/enums/enum.type";
import { CriteriaOwner } from "@core/api/api-client";

export const ReviewTypes = new Enums([
  new Enum({
    key: CriteriaOwner.OurTeam,
    name: "criteria_owner.team",
    route: ["team"],
    color: "red-600",
  }),
  new Enum({
    key: CriteriaOwner.Freelancer,
    name: "criteria_owner.freelancer",
    route: ["freelancer"],
    color: "blue-600",
  }),
  new Enum({
    key: CriteriaOwner.Client,
    name: "criteria_owner.client",
    route: ["client"],
    color: "green-600",
  }),
  new Enum({
    key: CriteriaOwner.User,
    name: "criteria_owner.user",
    route: ["user"],
    color: "orange-800",
  }),
]);

export const CriteriaOwners = new Enums([
  new Enum({
    key: CriteriaOwner.All,
    name: "criteria_owner.all",
    route: ["all"],
    color: "gray-600",
  }),
  ...ReviewTypes.models,
]);
