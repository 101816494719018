import { enableProdMode, StaticProvider } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { API_BASE_URL } from "@core/api/api-client";
import { environment } from "@env/environment";
import { AppModule } from "./app/app.module";

const providers: StaticProvider[] = [
  {
    provide: API_BASE_URL,
    useValue: environment.apiUrl,
  },
  {
    provide: "APP_VERSION",
    useValue:
      document.querySelectorAll("meta[name='version']")[0]?.getAttribute("content") ?? "",
  },
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
