import { Enum, Enums } from "@models/enums/enum.type";
import { PaymentMethod } from "@core/api/api-client";

export const CashInMethods = new Enums([
  new Enum({
    key: PaymentMethod.Card,
    name: "cash_in_method.card",
    route: ["card"],
    color: "green-400",
  }),
  new Enum({
    key: PaymentMethod.Mobile,
    name: "cash_in_method.mobile",
    route: ["mobile"],
    color: "red-400",
  }),
  new Enum({
    key: PaymentMethod.Kiosk,
    name: "cash_in_method.kiosk",
    route: ["kiosk"],
    color: "orange-400",
  }),
  new Enum({
    key: PaymentMethod.Cash,
    name: "cash_in_method.cash",
    route: ["cash"],
    color: "green-400",
  }),
]);
