import {
  ApplicationInsights,
  IExceptionTelemetry,
} from "@microsoft/applicationinsights-web";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "@env/environment";
import { ILoggerService } from "./logger.interface";
import { SeverityLevel } from "./severity-level";
import { IdentityManager } from "@core/auth/services/identity-manager.service";
import { Optional } from "@angular/core";

/**
 * API Documentation
 * https://github.com/microsoft/applicationinsights-js
 */

@Injectable()
export class AppLoggerService implements ILoggerService, OnDestroy {
  private readonly _subscription: Subscription;
  private _appInsights: ApplicationInsights;

  constructor(
    @Optional() @Inject("APP_VERSION") private _version: string,
    private _identityManager: IdentityManager
  ) {
    this._subscription = _identityManager.user$.subscribe((user) => {
      this._flushAppInsights();

      this._appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsights.connection,
          loggingLevelTelemetry: 2,
          enableAutoRouteTracking: true,
          accountId: user?.id,
          enableCorsCorrelation: true,
          enableAjaxErrorStatusText: true,
        },
      });

      if (user?.id) {
        this._appInsights.setAuthenticatedUserContext(user.id, user.email, true);
      }

      this._appInsights.loadAppInsights();

      this._appInsights.addTelemetryInitializer((envelope) => {
        envelope.data = {
          userId: user?.id ?? "guest",
          email: user?.email ?? "guest",
          version: _version,
        };
      });
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._flushAppInsights();
  }

  private _flushAppInsights(): void {
    if (this._appInsights) {
      this._appInsights.flush();
      this._appInsights = null;
    }
  }

  logPageView(name?: string, url?: string): void {
    this._appInsights.trackPageView({ name, uri: url });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this._appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this._appInsights.trackMetric({ name, average }, properties);
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    // this._appInsights.trackTrace({ message }, properties);
  }

  logException(
    exception: Error,
    severityLevel: SeverityLevel,
    properties?: { [key: string]: any }
  ): void {
    const ex: IExceptionTelemetry = { exception, severityLevel };

    if (properties) {
      ex.properties = properties;
    }

    this._appInsights.trackException(ex);
  }
}
