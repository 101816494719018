import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogContent } from "../dialog-content.interface";

@Component({
  templateUrl: "./modal-dialog.component.html",
})
export class ModalDialogComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: IDialogContent
  ) {}
}
