import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { LoggerService } from "@core/services/loggers/logger.service";
import { SeverityLevel } from "@core/services/loggers/severity-level";
import { TranslateService } from "@ngx-translate/core";

export const enum Script {
  Recaptcha,
  ApexCharts,
}

export interface ILazyScript {
  src: string;
  loaded?: boolean;
}

const STORE = new Map<Script, ILazyScript>([
  [
    Script.Recaptcha,
    {
      src: "https://www.google.com/recaptcha/api.js?render=explicit&onload=initRecaptcha",
    },
  ],
  [
    Script.ApexCharts,
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/apexcharts/3.41.0/apexcharts.min.js",
    },
  ],
]);

export interface ILazyScriptResult {
  script: Script;
  status: "loaded" | "failed" | "already-loaded";
  error?: any;
}

@Injectable()
export class LazyScriptService {
  constructor(
    @Inject(DOCUMENT) private readonly _dom: Document,
    private readonly _translate: TranslateService,
    private readonly _logger: LoggerService
  ) {}

  /** Dynamically load given scripts */
  load(...scripts: Script[]): Promise<ILazyScriptResult[]> {
    const promises: Promise<ILazyScriptResult>[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  /** Dynamically load a given script */
  loadScript(name: Script): Promise<ILazyScriptResult> {
    return new Promise((resolve) => {
      let script = STORE.get(name);

      // ## shouldn't be here consider move this out.
      if (name === Script.Recaptcha) {
        const isAr = this._translate.currentLang === "ar-EG";
        script = {
          ...script,
          src: script.src + `&hl=${isAr ? "ar" : "en"}`,
        };
      }

      // resolve if already loaded
      if (script.loaded) {
        resolve({ script: name, status: "already-loaded" });
        return;
      }

      // load script
      const node = this._dom.createElement("script");
      node.src = script.src;
      node.type = "text/javascript";
      node.async = true;

      // IE
      if ((node as any).readyState) {
        (node as any).onreadystatechange = () => {
          if (
            (node as any).readyState === "loaded" ||
            (node as any).readyState === "complete"
          ) {
            (node as any).onreadystatechange = null;
            script.loaded = true;
            resolve({ script: name, status: "loaded" });
          }
        };
      }
      // others
      else {
        node.onload = () => {
          script.loaded = true;
          resolve({ script: name, status: "loaded" });
        };
      }

      node.onerror = (error: any) => {
        this._logger.logException(error, SeverityLevel.Critical, {
          message: "Failed to load Recaptcha script",
        });
        resolve({ script: name, status: "failed", error });
      };

      document.getElementsByTagName("head")[0].appendChild(node);
    });
  }
}
