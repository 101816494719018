import { Enum, Enums } from "./enum.type";
import { AccountStatus } from "@core/api/api-client";

export const FreelancerStates = new Enums([
  new Enum({
    key: AccountStatus.Pending,
    name: "freelancer_state.pending",
    route: ["Pending"],
    color: "blue-400",
  }),
  new Enum({
    key: AccountStatus.Rejected,
    name: "freelancer_state.rejected",
    route: ["rejected"],
    color: "red-300",
  }),
  new Enum({
    key: AccountStatus.Accepted,
    name: "freelancer_state.accepted",
    route: ["accepted"],
    color: "light-green-500",
  }),
  new Enum({
    key: AccountStatus.Banned,
    name: "freelancer_state.ban",
    route: ["banned"],
    color: "red-800",
  }),
]);
