import { Enum, Enums } from "@models/enums/enum.type";
import { AcademicPosition } from "@core/api/api-client";

export const AcademicPositions = new Enums([
  new Enum({
    key: AcademicPosition.Graduate,
    name: "academic.graduated",
    route: ["graduate"],
    color: "yellow-600",
  }),
  new Enum({
    key: AcademicPosition.Student,
    name: "academic.student",
    route: ["student"],
    color: "orange-600",
  }),
]);
