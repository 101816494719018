import { Enums, Enum } from "./enum.type";
import { Country } from "@core/api/api-client";

export const Countries = new Enums([
  new Enum({
    key: Country.AF,
    name: "Afghanistan",
    route: ["AF"],
  }),
  new Enum({
    key: Country.AX,
    name: "Åland Islands",
    route: ["AX"],
  }),
  new Enum({
    key: Country.AL,
    name: "Albania",
    route: ["AL"],
  }),
  new Enum({
    key: Country.DZ,
    name: "Algeria",
    route: ["DZ"],
  }),
  new Enum({
    key: Country.AS,
    name: "American Samoa",
    route: ["AS"],
  }),
  new Enum({
    key: Country.AD,
    name: "Andorra",
    route: ["AD"],
  }),
  new Enum({
    key: Country.AO,
    name: "Angola",
    route: ["AO"],
  }),
  new Enum({
    key: Country.AI,
    name: "Anguilla",
    route: ["AI"],
  }),
  new Enum({
    key: Country.AQ,
    name: "Antarctica",
    route: ["AQ"],
  }),
  new Enum({
    key: Country.AG,
    name: "Antigua and Barbuda",
    route: ["AG"],
  }),
  new Enum({
    key: Country.AR,
    name: "Argentina",
    route: ["AR"],
  }),
  new Enum({
    key: Country.AM,
    name: "Armenia",
    route: ["AM"],
  }),
  new Enum({
    key: Country.AW,
    name: "Aruba",
    route: ["AW"],
  }),
  new Enum({
    key: Country.AU,
    name: "Australia",
    route: ["AU"],
  }),
  new Enum({
    key: Country.AT,
    name: "Austria",
    route: ["AT"],
  }),
  new Enum({
    key: Country.AZ,
    name: "Azerbaijan",
    route: ["AZ"],
  }),
  new Enum({
    key: Country.BS,
    name: "Bahamas",
    route: ["BS"],
  }),
  new Enum({
    key: Country.BH,
    name: "Bahrain",
    route: ["BH"],
  }),
  new Enum({
    key: Country.BD,
    name: "Bangladesh",
    route: ["BD"],
  }),
  new Enum({
    key: Country.BB,
    name: "Barbados",
    route: ["BB"],
  }),
  new Enum({
    key: Country.BY,
    name: "Belarus",
    route: ["BY"],
  }),
  new Enum({
    key: Country.BE,
    name: "Belgium",
    route: ["BE"],
  }),
  new Enum({
    key: Country.BZ,
    name: "Belize",
    route: ["BZ"],
  }),
  new Enum({
    key: Country.BJ,
    name: "Benin",
    route: ["BJ"],
  }),
  new Enum({
    key: Country.BM,
    name: "Bermuda",
    route: ["BM"],
  }),
  new Enum({
    key: Country.BT,
    name: "Bhutan",
    route: ["BT"],
  }),
  new Enum({
    key: Country.BO,
    name: "Bolivia (Plurinational State of)",
    route: ["BO"],
  }),
  new Enum({
    key: Country.BQ,
    name: "Bonaire, Sint Eustatius and Saba",
    route: ["BQ"],
  }),
  new Enum({
    key: Country.BA,
    name: "Bosnia and Herzegovina",
    route: ["BA"],
  }),
  new Enum({
    key: Country.BW,
    name: "Botswana",
    route: ["BW"],
  }),
  new Enum({
    key: Country.BV,
    name: "Bouvet Island",
    route: ["BV"],
  }),
  new Enum({
    key: Country.BR,
    name: "Brazil",
    route: ["BR"],
  }),
  new Enum({
    key: Country.IO,
    name: "British Indian Ocean Territory",
    route: ["IO"],
  }),
  new Enum({
    key: Country.BN,
    name: "Brunei Darussalam",
    route: ["BN"],
  }),
  new Enum({
    key: Country.BG,
    name: "Bulgaria",
    route: ["BG"],
  }),
  new Enum({
    key: Country.BF,
    name: "Burkina Faso",
    route: ["BF"],
  }),
  new Enum({
    key: Country.BI,
    name: "Burundi",
    route: ["BI"],
  }),
  new Enum({
    key: Country.CV,
    name: "Cabo Verde",
    route: ["CV"],
  }),
  new Enum({
    key: Country.KH,
    name: "Cambodia",
    route: ["KH"],
  }),
  new Enum({
    key: Country.CM,
    name: "Cameroon",
    route: ["CM"],
  }),
  new Enum({
    key: Country.CA,
    name: "Canada",
    route: ["CA"],
  }),
  new Enum({
    key: Country.KY,
    name: "Cayman Islands",
    route: ["KY"],
  }),
  new Enum({
    key: Country.CF,
    name: "Central African Republic",
    route: ["CF"],
  }),
  new Enum({
    key: Country.TD,
    name: "Chad",
    route: ["TD"],
  }),
  new Enum({
    key: Country.CL,
    name: "Chile",
    route: ["CL"],
  }),
  new Enum({
    key: Country.CN,
    name: "China",
    route: ["CN"],
  }),
  new Enum({
    key: Country.CX,
    name: "Christmas Island",
    route: ["CX"],
  }),
  new Enum({
    key: Country.CC,
    name: "Cocos (Keeling) Islands",
    route: ["CC"],
  }),
  new Enum({
    key: Country.CO,
    name: "Colombia",
    route: ["CO"],
  }),
  new Enum({
    key: Country.KM,
    name: "Comoros",
    route: ["KM"],
  }),
  new Enum({
    key: Country.CG,
    name: "Congo",
    route: ["CG"],
  }),
  new Enum({
    key: Country.CD,
    name: "Congo (Democratic Republic of the)",
    route: ["CD"],
  }),
  new Enum({
    key: Country.CK,
    name: "Cook Islands",
    route: ["CK"],
  }),
  new Enum({
    key: Country.CR,
    name: "Costa Rica",
    route: ["CR"],
  }),
  new Enum({
    key: Country.CI,
    name: "Côte d'Ivoire",
    route: ["CI"],
  }),
  new Enum({
    key: Country.HR,
    name: "Croatia",
    route: ["HR"],
  }),
  new Enum({
    key: Country.CU,
    name: "Cuba",
    route: ["CU"],
  }),
  new Enum({
    key: Country.CW,
    name: "Curaçao",
    route: ["CW"],
  }),
  new Enum({
    key: Country.CY,
    name: "Cyprus",
    route: ["CY"],
  }),
  new Enum({
    key: Country.CZ,
    name: "Czechia",
    route: ["CZ"],
  }),
  new Enum({
    key: Country.DK,
    name: "Denmark",
    route: ["DK"],
  }),
  new Enum({
    key: Country.DJ,
    name: "Djibouti",
    route: ["DJ"],
  }),
  new Enum({
    key: Country.DM,
    name: "Dominica",
    route: ["DM"],
  }),
  new Enum({
    key: Country.DO,
    name: "Dominican Republic",
    route: ["DO"],
  }),
  new Enum({
    key: Country.EC,
    name: "Ecuador",
    route: ["EC"],
  }),
  new Enum({
    key: Country.EG,
    name: "Egypt",
    route: ["EG"],
  }),
  new Enum({
    key: Country.SV,
    name: "El Salvador",
    route: ["SV"],
  }),
  new Enum({
    key: Country.GQ,
    name: "Equatorial Guinea",
    route: ["GQ"],
  }),
  new Enum({
    key: Country.ER,
    name: "Eritrea",
    route: ["ER"],
  }),
  new Enum({
    key: Country.EE,
    name: "Estonia",
    route: ["EE"],
  }),
  new Enum({
    key: Country.ET,
    name: "Ethiopia",
    route: ["ET"],
  }),
  new Enum({
    key: Country.FK,
    name: "Falkland Islands (Malvinas)",
    route: ["FK"],
  }),
  new Enum({
    key: Country.FO,
    name: "Faroe Islands",
    route: ["FO"],
  }),
  new Enum({
    key: Country.FJ,
    name: "Fiji",
    route: ["FJ"],
  }),
  new Enum({
    key: Country.FI,
    name: "Finland",
    route: ["FI"],
  }),
  new Enum({
    key: Country.FR,
    name: "France",
    route: ["FR"],
  }),
  new Enum({
    key: Country.GF,
    name: "French Guiana",
    route: ["GF"],
  }),
  new Enum({
    key: Country.PF,
    name: "French Polynesia",
    route: ["PF"],
  }),
  new Enum({
    key: Country.TF,
    name: "French Southern Territories",
    route: ["TF"],
  }),
  new Enum({
    key: Country.GA,
    name: "Gabon",
    route: ["GA"],
  }),
  new Enum({
    key: Country.GM,
    name: "Gambia",
    route: ["GM"],
  }),
  new Enum({
    key: Country.GE,
    name: "Georgia",
    route: ["GE"],
  }),
  new Enum({
    key: Country.DE,
    name: "Germany",
    route: ["DE"],
  }),
  new Enum({
    key: Country.GH,
    name: "Ghana",
    route: ["GH"],
  }),
  new Enum({
    key: Country.GI,
    name: "Gibraltar",
    route: ["GI"],
  }),
  new Enum({
    key: Country.GR,
    name: "Greece",
    route: ["GR"],
  }),
  new Enum({
    key: Country.GL,
    name: "Greenland",
    route: ["GL"],
  }),
  new Enum({
    key: Country.GD,
    name: "Grenada",
    route: ["GD"],
  }),
  new Enum({
    key: Country.GP,
    name: "Guadeloupe",
    route: ["GP"],
  }),
  new Enum({
    key: Country.GU,
    name: "Guam",
    route: ["GU"],
  }),
  new Enum({
    key: Country.GT,
    name: "Guatemala",
    route: ["GT"],
  }),
  new Enum({
    key: Country.GG,
    name: "Guernsey",
    route: ["GG"],
  }),
  new Enum({
    key: Country.GN,
    name: "Guinea",
    route: ["GN"],
  }),
  new Enum({
    key: Country.GW,
    name: "Guinea-Bissau",
    route: ["GW"],
  }),
  new Enum({
    key: Country.GY,
    name: "Guyana",
    route: ["GY"],
  }),
  new Enum({
    key: Country.HT,
    name: "Haiti",
    route: ["HT"],
  }),
  new Enum({
    key: Country.HM,
    name: "Heard Island and McDonald Islands",
    route: ["HM"],
  }),
  new Enum({
    key: Country.VA,
    name: "Holy See",
    route: ["VA"],
  }),
  new Enum({
    key: Country.HN,
    name: "Honduras",
    route: ["HN"],
  }),
  new Enum({
    key: Country.HK,
    name: "Hong Kong",
    route: ["HK"],
  }),
  new Enum({
    key: Country.HU,
    name: "Hungary",
    route: ["HU"],
  }),
  new Enum({
    key: Country.IS,
    name: "Iceland",
    route: ["IS"],
  }),
  new Enum({
    key: Country.IN,
    name: "India",
    route: ["IN"],
  }),
  new Enum({
    key: Country.ID,
    name: "Indonesia",
    route: ["ID"],
  }),
  new Enum({
    key: Country.IR,
    name: "Iran (Islamic Republic of)",
    route: ["IR"],
  }),
  new Enum({
    key: Country.IQ,
    name: "Iraq",
    route: ["IQ"],
  }),
  new Enum({
    key: Country.IE,
    name: "Ireland",
    route: ["IE"],
  }),
  new Enum({
    key: Country.IM,
    name: "Isle of Man",
    route: ["IM"],
  }),
  new Enum({
    key: Country.IL,
    name: "Israel",
    route: ["IL"],
  }),
  new Enum({
    key: Country.IT,
    name: "Italy",
    route: ["IT"],
  }),
  new Enum({
    key: Country.JM,
    name: "Jamaica",
    route: ["JM"],
  }),
  new Enum({
    key: Country.JP,
    name: "Japan",
    route: ["JP"],
  }),
  new Enum({
    key: Country.JE,
    name: "Jersey",
    route: ["JE"],
  }),
  new Enum({
    key: Country.JO,
    name: "Jordan",
    route: ["JO"],
  }),
  new Enum({
    key: Country.KZ,
    name: "Kazakhstan",
    route: ["KZ"],
  }),
  new Enum({
    key: Country.KE,
    name: "Kenya",
    route: ["KE"],
  }),
  new Enum({
    key: Country.KI,
    name: "Kiribati",
    route: ["KI"],
  }),
  new Enum({
    key: Country.KP,
    name: "Korea (Democratic People's Republic of)",
    route: ["KP"],
  }),
  new Enum({
    key: Country.KR,
    name: "Korea (Republic of)",
    route: ["KR"],
  }),
  new Enum({
    key: Country.KW,
    name: "Kuwait",
    route: ["KW"],
  }),
  new Enum({
    key: Country.KG,
    name: "Kyrgyzstan",
    route: ["KG"],
  }),
  new Enum({
    key: Country.LA,
    name: "Lao People's Democratic Republic",
    route: ["LA"],
  }),
  new Enum({
    key: Country.LV,
    name: "Latvia",
    route: ["LV"],
  }),
  new Enum({
    key: Country.LB,
    name: "Lebanon",
    route: ["LB"],
  }),
  new Enum({
    key: Country.LS,
    name: "Lesotho",
    route: ["LS"],
  }),
  new Enum({
    key: Country.LR,
    name: "Liberia",
    route: ["LR"],
  }),
  new Enum({
    key: Country.LY,
    name: "Libya",
    route: ["LY"],
  }),
  new Enum({
    key: Country.LI,
    name: "Liechtenstein",
    route: ["LI"],
  }),
  new Enum({
    key: Country.LT,
    name: "Lithuania",
    route: ["LT"],
  }),
  new Enum({
    key: Country.LU,
    name: "Luxembourg",
    route: ["LU"],
  }),
  new Enum({
    key: Country.MO,
    name: "Macao",
    route: ["MO"],
  }),
  new Enum({
    key: Country.MK,
    name: "Macedonia (the former Yugoslav Republic of)",
    route: ["MK"],
  }),
  new Enum({
    key: Country.MG,
    name: "Madagascar",
    route: ["MG"],
  }),
  new Enum({
    key: Country.MW,
    name: "Malawi",
    route: ["MW"],
  }),
  new Enum({
    key: Country.MY,
    name: "Malaysia",
    route: ["MY"],
  }),
  new Enum({
    key: Country.MV,
    name: "Maldives",
    route: ["MV"],
  }),
  new Enum({
    key: Country.ML,
    name: "Mali",
    route: ["ML"],
  }),
  new Enum({
    key: Country.MT,
    name: "Malta",
    route: ["MT"],
  }),
  new Enum({
    key: Country.MH,
    name: "Marshall Islands",
    route: ["MH"],
  }),
  new Enum({
    key: Country.MQ,
    name: "Martinique",
    route: ["MQ"],
  }),
  new Enum({
    key: Country.MR,
    name: "Mauritania",
    route: ["MR"],
  }),
  new Enum({
    key: Country.MU,
    name: "Mauritius",
    route: ["MU"],
  }),
  new Enum({
    key: Country.YT,
    name: "Mayotte",
    route: ["YT"],
  }),
  new Enum({
    key: Country.MX,
    name: "Mexico",
    route: ["MX"],
  }),
  new Enum({
    key: Country.FM,
    name: "Micronesia (Federated States of)",
    route: ["FM"],
  }),
  new Enum({
    key: Country.MD,
    name: "Moldova (Republic of)",
    route: ["MD"],
  }),
  new Enum({
    key: Country.MC,
    name: "Monaco",
    route: ["MC"],
  }),
  new Enum({
    key: Country.MN,
    name: "Mongolia",
    route: ["MN"],
  }),
  new Enum({
    key: Country.ME,
    name: "Montenegro",
    route: ["ME"],
  }),
  new Enum({
    key: Country.MS,
    name: "Montserrat",
    route: ["MS"],
  }),
  new Enum({
    key: Country.MA,
    name: "Morocco",
    route: ["MA"],
  }),
  new Enum({
    key: Country.MZ,
    name: "Mozambique",
    route: ["MZ"],
  }),
  new Enum({
    key: Country.MM,
    name: "Myanmar",
    route: ["MM"],
  }),
  new Enum({
    key: Country.NA,
    name: "Namibia",
    route: ["NA"],
  }),
  new Enum({
    key: Country.NR,
    name: "Nauru",
    route: ["NR"],
  }),
  new Enum({
    key: Country.NP,
    name: "Nepal",
    route: ["NP"],
  }),
  new Enum({
    key: Country.NL,
    name: "Netherlands",
    route: ["NL"],
  }),
  new Enum({
    key: Country.NC,
    name: "New Caledonia",
    route: ["NC"],
  }),
  new Enum({
    key: Country.NZ,
    name: "New Zealand",
    route: ["NZ"],
  }),
  new Enum({
    key: Country.NI,
    name: "Nicaragua",
    route: ["NI"],
  }),
  new Enum({
    key: Country.NE,
    name: "Niger",
    route: ["NE"],
  }),
  new Enum({
    key: Country.NG,
    name: "Nigeria",
    route: ["NG"],
  }),
  new Enum({
    key: Country.NU,
    name: "Niue",
    route: ["NU"],
  }),
  new Enum({
    key: Country.NF,
    name: "Norfolk Island",
    route: ["NF"],
  }),
  new Enum({
    key: Country.MP,
    name: "Northern Mariana Islands",
    route: ["MP"],
  }),
  new Enum({
    key: Country.NO,
    name: "Norway",
    route: ["NO"],
  }),
  new Enum({
    key: Country.OM,
    name: "Oman",
    route: ["OM"],
  }),
  new Enum({
    key: Country.PK,
    name: "Pakistan",
    route: ["PK"],
  }),
  new Enum({
    key: Country.PW,
    name: "Palau",
    route: ["PW"],
  }),
  new Enum({
    key: Country.PS,
    name: "Palestine, State of",
    route: ["PS"],
  }),
  new Enum({
    key: Country.PA,
    name: "Panama",
    route: ["PA"],
  }),
  new Enum({
    key: Country.PG,
    name: "Papua New Guinea",
    route: ["PG"],
  }),
  new Enum({
    key: Country.PY,
    name: "Paraguay",
    route: ["PY"],
  }),
  new Enum({
    key: Country.PE,
    name: "Peru",
    route: ["PE"],
  }),
  new Enum({
    key: Country.PH,
    name: "Philippines",
    route: ["PH"],
  }),
  new Enum({
    key: Country.PN,
    name: "Pitcairn",
    route: ["PN"],
  }),
  new Enum({
    key: Country.PL,
    name: "Poland",
    route: ["PL"],
  }),
  new Enum({
    key: Country.PT,
    name: "Portugal",
    route: ["PT"],
  }),
  new Enum({
    key: Country.PR,
    name: "Puerto Rico",
    route: ["PR"],
  }),
  new Enum({
    key: Country.QA,
    name: "Qatar",
    route: ["QA"],
  }),
  new Enum({
    key: Country.RE,
    name: "Réunion",
    route: ["RE"],
  }),
  new Enum({
    key: Country.RO,
    name: "Romania",
    route: ["RO"],
  }),
  new Enum({
    key: Country.RU,
    name: "Russian Federation",
    route: ["RU"],
  }),
  new Enum({
    key: Country.RW,
    name: "Rwanda",
    route: ["RW"],
  }),
  new Enum({
    key: Country.BL,
    name: "Saint Barthélemy",
    route: ["BL"],
  }),
  new Enum({
    key: Country.SH,

    name: "Saint Helena, Ascension and Tristan da Cunha",

    route: ["SH"],
  }),

  new Enum({
    key: Country.KN,
    name: "Saint Kitts and Nevis",
    route: ["KN"],
  }),
  new Enum({
    key: Country.LC,
    name: "Saint Lucia",
    route: ["LC"],
  }),
  new Enum({
    key: Country.MF,
    name: "Saint Martin (French part)",
    route: ["MF"],
  }),
  new Enum({
    key: Country.PM,
    name: "Saint Pierre and Miquelon",
    route: ["PM"],
  }),
  new Enum({
    key: Country.VC,
    name: "Saint Vincent and the Grenadines",
    route: ["VC"],
  }),
  new Enum({
    key: Country.WS,
    name: "Samoa",
    route: ["WS"],
  }),
  new Enum({
    key: Country.SM,
    name: "San Marino",
    route: ["SM"],
  }),
  new Enum({
    key: Country.ST,
    name: "Sao Tome and Principe",
    route: ["ST"],
  }),
  new Enum({
    key: Country.SA,
    name: "Saudi Arabia",
    route: ["SA"],
  }),
  new Enum({
    key: Country.SN,
    name: "Senegal",
    route: ["SN"],
  }),
  new Enum({
    key: Country.RS,
    name: "Serbia",
    route: ["RS"],
  }),
  new Enum({
    key: Country.SC,
    name: "Seychelles",
    route: ["SC"],
  }),
  new Enum({
    key: Country.SL,
    name: "Sierra Leone",
    route: ["SL"],
  }),
  new Enum({
    key: Country.SG,
    name: "Singapore",
    route: ["SG"],
  }),
  new Enum({
    key: Country.SX,
    name: "Sint Maarten (Dutch part)",
    route: ["SX"],
  }),
  new Enum({
    key: Country.SK,
    name: "Slovakia",
    route: ["SK"],
  }),
  new Enum({
    key: Country.SI,
    name: "Slovenia",
    route: ["SI"],
  }),
  new Enum({
    key: Country.SB,
    name: "Solomon Islands",
    route: ["SB"],
  }),
  new Enum({
    key: Country.SO,
    name: "Somalia",
    route: ["SO"],
  }),
  new Enum({
    key: Country.ZA,
    name: "South Africa",
    route: ["ZA"],
  }),
  new Enum({
    key: Country.GS,
    name: "South Georgia and the South Sandwich Islands",
    route: ["GS"],
  }),
  new Enum({
    key: Country.SS,
    name: "South Sudan",
    route: ["SS"],
  }),
  new Enum({
    key: Country.ES,
    name: "Spain",
    route: ["ES"],
  }),
  new Enum({
    key: Country.LK,
    name: "Sri Lanka",
    route: ["LK"],
  }),
  new Enum({
    key: Country.SD,
    name: "Sudan",
    route: ["SD"],
  }),
  new Enum({
    key: Country.SR,
    name: "Suriname",
    route: ["SR"],
  }),
  new Enum({
    key: Country.SJ,
    name: "Svalbard and Jan Mayen",
    route: ["SJ"],
  }),
  new Enum({
    key: Country.SZ,
    name: "Swaziland",
    route: ["SZ"],
  }),
  new Enum({
    key: Country.SE,
    name: "Sweden",
    route: ["SE"],
  }),
  new Enum({
    key: Country.CH,
    name: "Switzerland",
    route: ["CH"],
  }),
  new Enum({
    key: Country.SY,
    name: "Syrian Arab Republic",
    route: ["SY"],
  }),
  new Enum({
    key: Country.TW,
    name: "Taiwan, Province of China[a]",
    route: ["TW"],
  }),
  new Enum({
    key: Country.TJ,
    name: "Tajikistan",
    route: ["TJ"],
  }),
  new Enum({
    key: Country.TZ,
    name: "Tanzania, United Republic of",
    route: ["TZ"],
  }),
  new Enum({
    key: Country.TH,
    name: "Thailand",
    route: ["TH"],
  }),
  new Enum({
    key: Country.TL,
    name: "Timor-Leste",
    route: ["TL"],
  }),
  new Enum({
    key: Country.TG,
    name: "Togo",
    route: ["TG"],
  }),
  new Enum({
    key: Country.TK,
    name: "Tokelau",
    route: ["TK"],
  }),
  new Enum({
    key: Country.TO,
    name: "Tonga",
    route: ["TO"],
  }),
  new Enum({
    key: Country.TT,
    name: "Trinidad and Tobago",
    route: ["TT"],
  }),
  new Enum({
    key: Country.TN,
    name: "Tunisia",
    route: ["TN"],
  }),
  new Enum({
    key: Country.TR,
    name: "Turkey",
    route: ["TR"],
  }),
  new Enum({
    key: Country.TM,
    name: "Turkmenistan",
    route: ["TM"],
  }),
  new Enum({
    key: Country.TC,
    name: "Turks and Caicos Islands",
    route: ["TC"],
  }),
  new Enum({
    key: Country.TV,
    name: "Tuvalu",
    route: ["TV"],
  }),
  new Enum({
    key: Country.UG,
    name: "Uganda",
    route: ["UG"],
  }),
  new Enum({
    key: Country.UA,
    name: "Ukraine",
    route: ["UA"],
  }),
  new Enum({
    key: Country.AE,
    name: "United Arab Emirates",
    route: ["AE"],
  }),
  new Enum({
    key: Country.GB,

    name: "United Kingdom of Great Britain and Northern Ireland",

    route: ["GB"],
  }),

  new Enum({
    key: Country.US,
    name: "United States of America",
    route: ["US"],
  }),
  new Enum({
    key: Country.UM,
    name: "United States Minor Outlying Islands",
    route: ["UM"],
  }),
  new Enum({
    key: Country.UY,
    name: "Uruguay",
    route: ["UY"],
  }),
  new Enum({
    key: Country.UZ,
    name: "Uzbekistan",
    route: ["UZ"],
  }),
  new Enum({
    key: Country.VU,
    name: "Vanuatu",
    route: ["VU"],
  }),
  new Enum({
    key: Country.VE,
    name: "Venezuela (Bolivarian Republic of)",
    route: ["VE"],
  }),
  new Enum({
    key: Country.VN,
    name: "Viet Nam",
    route: ["VN"],
  }),
  new Enum({
    key: Country.VG,
    name: "Virgin Islands (British)",
    route: ["VG"],
  }),
  new Enum({
    key: Country.VI,
    name: "Virgin Islands (U.S.)",
    route: ["VI"],
  }),
  new Enum({
    key: Country.WF,
    name: "Wallis and Futuna",
    route: ["WF"],
  }),
  new Enum({
    key: Country.EH,
    name: "Western Sahara",
    route: ["EH"],
  }),
  new Enum({
    key: Country.YE,
    name: "Yemen",
    route: ["YE"],
  }),
  new Enum({
    key: Country.ZM,
    name: "Zambia",
    route: ["ZM"],
  }),
  new Enum({
    key: Country.ZW,
    name: "Zimbabwe",
    route: ["ZW"],
  }),
]);
