import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IdentityManager } from "../services/identity-manager.service";
import { ModalService } from "@core/services";
import { PATHS } from "@models";

@Injectable()
export class UnauthenticatedGuard implements CanActivate, CanLoad {
  constructor(
    private readonly _identityManager: IdentityManager,
    private readonly _modal: ModalService,
    private readonly _router: Router
  ) {}

  canLoad(_route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    return this._handle();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._handle();
  }

  private _handle(): boolean {
    if (this._identityManager.isAuthenticated) {
      this._router.navigateByUrl(PATHS.Home).then(() => {
        this._modal.snackbarInLocal({ token: "errors.logged_in" });
      });

      return false;
    }

    return true;
  }
}
