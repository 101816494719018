<h1 mat-dialog-title class="text-center text-gray-800">
  <mat-icon svgIcon="chevron-right"></mat-icon>
  {{ data.title }}
</h1>

<div mat-dialog-content class="m-y-8" [innerHTML]="data.message"></div>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]="0">{{ "keywords.cancel" | translate }}</button>
  <button
    mat-flat-button
    *ngFor="let option of data.options"
    [class]="option.style + ' m-l-8'"
    [mat-dialog-close]="option.output"
  >
    {{ option.title }}
  </button>
</div>
