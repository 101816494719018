import {
  CreateAccountDialogComponent,
  ModalDialogComponent,
  ConfirmDialogComponent,
  OptionsDialogComponent,
} from "./dialogs";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "./material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ToastrModule } from "ngx-toastr";
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressRouterModule } from "ngx-progressbar/router";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { DropZoneDirective } from "./directives/drop-zone/drop-zone.directive";
import { VarDirective } from "./directives/ng-var.directive";
import { TranslateModule } from "@ngx-translate/core";
import { BreadcrumbModule } from "@widgets/breadcrumb/breadcrumb.module";
import { PageHeaderModule } from "@widgets/page-header/page-header.module";
import { PIPES } from "./pipes/pipes";
import { ChatNotAvailableDialogComponent } from "@shared/dialogs/chat-not-available-dialog/chat-not-available-dialog.component";

const LOCAL_COMPONENTS: any[] = [];

const SHARED_DIRECTIVES = [VarDirective];

const LOCAL_DIRECTIVES = [DropZoneDirective];

const DIALOGS = [
  ModalDialogComponent,
  ConfirmDialogComponent,
  OptionsDialogComponent,
  CreateAccountDialogComponent,
  ChatNotAvailableDialogComponent,
];

const THIRD_MODULES = [
  BreadcrumbModule,
  PageHeaderModule,
  MaterialModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgProgressHttpModule,
  ToastrModule,
];

const COMMON_MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [
    ...PIPES,
    ...DIALOGS,
    ...LOCAL_COMPONENTS,
    ...LOCAL_DIRECTIVES,
    ...SHARED_DIRECTIVES,
  ],
  imports: [
    ...COMMON_MODULES,
    ...THIRD_MODULES,
    TranslateModule.forChild({ extend: true }),
  ],
  exports: [
    ...COMMON_MODULES,
    ...THIRD_MODULES,
    ...SHARED_DIRECTIVES,
    ...PIPES,
    TranslateModule,
  ],
})
export class SharedModule {}

@NgModule({
  imports: [CommonModule, TranslateModule.forChild({ extend: true })],
  exports: [CommonModule, TranslateModule],
})
export class MinSharedModule {}
