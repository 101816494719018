import { Enum, Enums } from "@models/enums/enum.type";
import { PaymentStatus } from "@core/api/api-client";

export const PaymentStates = new Enums([
  new Enum({
    key: PaymentStatus.OnHold,
    name: "payment_state.hold",
    route: ["hold"],
    color: "green-400",
  }),
  new Enum({
    key: PaymentStatus.Funded,
    name: "payment_state.funded",
    route: ["funded"],
    color: "blue-400",
  }),
  new Enum({
    key: PaymentStatus.Released,
    name: "payment_state.released",
    route: ["released"],
    color: "blue-400",
  }),
]);
