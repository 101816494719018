import {
  ChatMemberVm,
  ChatsClient,
  FileParameter,
  FreelancersClient,
  MessageType,
  MessageVm,
  UserType,
} from "@core/api/api-client";
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { IdentityManager } from "@core/auth";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, of, Subject, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Categories } from "@models";
import { debug, genericRetryStrategy } from "@core/utils";
import { take as strTake } from "@shared/pipes/take.pipe";
import { nameToString } from "@shared/pipes/name-to-string.pipe";
import { SeverityLevel } from "../loggers/severity-level";
import { LoggerService } from "../loggers/logger.service";
import { DataLayerService } from "../tracking/data-layer.service";
import { RealTimeService } from "../real-time.service";
import { ModalService } from "../modals/modal.service";
import { ToastService } from "../modals/toast.service";
import { Chats } from "./chats";
import { Chat, ChatMember } from "./chat";

// TODO: when reconnect from realtime service reload messages

@Injectable()
export class ChatManager implements OnDestroy {
  constructor(
    private readonly _identityManager: IdentityManager,
    private readonly _chatsClient: ChatsClient,
    private readonly _freelancersClient: FreelancersClient,
    private readonly _realtime: RealTimeService,
    private readonly _translate: TranslateService,
    private readonly _modal: ModalService,
    private readonly _tracker: DataLayerService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService
  ) {
    this._identityManager.isAuthenticated$
      .pipe(takeUntil(this._destroy$))
      .subscribe((auth) => {
        if (!auth) {
          return this._clear();
        }

        this._chatsClient
          .get()
          .pipe(retryWhen(genericRetryStrategy()))
          .subscribe((chats) => {
            const items = chats?.map((chat) => Chat.from(chat));
            this._chats.addAll(items);
          });
      });

    this._setupRealtime();
  }

  private readonly _destroy$ = new Subject<void>();
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  private readonly _current$ = new BehaviorSubject<Chat | null>(null);
  private _chats = new Chats();

  /** Is chat sidebar open */
  get isOpen$(): Observable<boolean> {
    return this._isOpen$.pipe(distinctUntilChanged());
  }

  /** Current opened chat */
  get current$(): Observable<Chat | null> {
    return this._current$.pipe(distinctUntilChanged((x, y) => x?.id === y?.id));
  }

  /** Get chats */
  get chats$(): Observable<Chat> {
    return this._chats.items$;
  }

  /** Get observable of number of unread messages. */
  get unread$(): Observable<number> {
    return this._chats.unread$;
  }

  ngOnDestroy(): void {
    this._clear();
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _clear(): void {
    this._isOpen$.next(false);
    this._current$.next(null);
    this._chats = new Chats();
  }

  /** Set current opened chat */
  changeCurrent(chat: Chat | null): Observable<void> {
    if (!chat) {
      this._current$.next(null);
      return EMPTY;
    }

    if (chat.isLoaded) {
      this._current$.next(chat);
      return EMPTY;
    }

    return this.loadMessages(chat).pipe(
      tap(() => this._current$.next(chat)),
      map((_) => {})
    );
  }

  /** set chat as open */
  open(): void {
    if (!this._isOpen$.value) {
      this._isOpen$.next(true);
    }
  }

  /** set chat as close */
  close(): void {
    if (this._isOpen$.value) {
      this._isOpen$.next(false);
    }
  }

  /** Start new chat or get if already exist. */
  start(accountId: string): Observable<void> {
    if (!this._identityManager.isAuthenticated) {
      this._modal.snackbarInLocal({ token: "services.chat.require_login" });
      return EMPTY;
    }

    const setAsCurrentChatThenOpen = (chat: Chat) =>
      this.changeCurrent(chat).pipe(tap(() => this.open()));

    const exist = this._chats.findByPartner(accountId);
    if (exist) {
      return setAsCurrentChatThenOpen(exist);
    }

    this._logger.logTrace(`StartChat: No chat with ${accountId} try to start it`);

    return this._chatsClient.startDuoChat({ partnerId: accountId }).pipe(
      mergeMap((id) => this._getChat(id)),
      debug("StartChat: Start new chat"),
      switchMap(setAsCurrentChatThenOpen),
      tap(() => this._fireStartChatEvent(accountId))
    );
  }

  private _fireStartChatEvent(accountId: string): void {
    this._freelancersClient.find(accountId).subscribe((freelancer) => {
      this._tracker.trackEvent("click #start_chat", {
        name: nameToString(freelancer.name),
        userName: freelancer.userName,
        category: Categories.get(freelancer.category)?.name,
      });
    });
  }

  /** Send text message in the current chat. */
  sendTextMessage(message?: string, files?: FileParameter[]): Observable<void> {
    const chat = this._current$.value;

    if (!chat) {
      throw new Error("Current chat is empty can't send text message!");
    }

    return this._chatsClient.sendTextMessage(chat.id, message, files).pipe(
      tap((message) => chat.send(chat.toMessage(message))),
      map((_) => {})
    );
  }

  /** Send voice note message in the current chat. */
  sendVoiceNote(voice?: FileParameter, files?: FileParameter[]): Observable<void> {
    const chat = this._current$.value;

    if (!chat) {
      throw new Error("Current chat is empty can't send text message!");
    }

    return this._chatsClient.sendVoiceNote(chat.id, voice, files).pipe(
      tap((message) => chat.send(chat.toMessage(message))),
      map((_) => {})
    );
  }

  /** Read unread messages in the current chat */
  read(): void {
    // TODO: specify messages id instead of mark all as read

    const chat = this._current$.value;

    if (!chat) {
      throw new Error("Current chat is empty can't send text message!");
    }

    this._chatsClient
      .readMark(chat.id)
      .pipe(
        debug("Read messages"),
        tap(() => chat.read(this._identityManager.user.id))
      )
      .subscribe();
  }

  loadMessages(chat: Chat): Observable<void> {
    if (!chat.hasMore) {
      return EMPTY;
    }

    if (!chat.isLoaded) {
      return this._chatsClient.getMessagesPage(chat.id, 0, 25).pipe(
        retryWhen(genericRetryStrategy()),
        debug("Load first messages page"),
        map((p) => chat.load(p.items)),
        catchError((err) => {
          const message = "ChatManager - can not load first messages page";
          this._logger.logException(err, SeverityLevel.Error, { message });
          return throwError(err);
        })
      );
    }

    const first = chat.firstLoaded();

    return this._chatsClient.getMessages(25, first.id, chat.id, true).pipe(
      retryWhen(genericRetryStrategy()),
      debug("Load messages"),
      map((messages) => chat.load(messages)),
      catchError((err) => {
        const message = "ChatManager - can not load messages";
        this._logger.logException(err, SeverityLevel.Error, { message });
        return throwError(err);
      })
    );
  }

  private _setupRealtime(): void {
    this._realtime.message$
      .pipe(
        takeUntil(this._destroy$),
        concatMap((m) =>
          this._getChat(m.chatId).pipe(map((c) => ({ c, m: c.toMessage(m) })))
        ),
        concatMap(({ c, m }) =>
          this._getMember(c, m.sendBy).pipe(map((s) => ({ c, m, s })))
        )
      )
      .subscribe(({ c, m, s }) => {
        c.send(m);

        // Sender may not exist if it system message & caused by current user (sender)
        if (s) {
          this._handleNotification(m, s);
        }
      });
  }

  private _getChat(chatId: number): Observable<Chat> {
    const chat = this._chats.find(chatId);
    if (chat) {
      return of(chat);
    }

    return this._chatsClient.find(chatId).pipe(
      map((chat) => Chat.from(chat)),
      tap((chat) => this._chats.add(chat))
    );
  }

  private _getMember(chat: Chat, accountId: string): Observable<ChatMember> {
    const member = chat.members.find((m) => m.id === accountId);

    if (member) {
      return of(member);
    }

    return this._chatsClient
      .findMember(chat.id, accountId)
      .pipe(tap((m) => chat.join(m)));
  }

  private _handleNotification(message: MessageVm, sender: ChatMemberVm): void {
    new Audio("assets/mp3/swiftly.mp3").play();

    const title =
      message.type === MessageType.System
        ? this._translate.instant("services.chat.new_system_message")
        : sender.type === UserType.Team
        ? this._translate.instant("services.chat.new_support_message")
        : this._translate.instant("services.chat.new_message");

    const content = this._notificationContent(message, sender);

    this._toast.show(content, title);
  }

  private _notificationContent(message: MessageVm, sender: ChatMemberVm): string {
    if (message.type === MessageType.System) {
      return strTake(message.message, 150);
    }

    const name =
      sender.type === UserType.Team
        ? this._translate.instant("services.chat.support")
        : sender.name.firstName + " " + sender.name.lastName;

    return message.files && message.files.length > 0
      ? this._translate.instant("services.chat.new_files", { name })
      : message.type === MessageType.Text
      ? `${name}: ${strTake(message.message, 150)}`
      : this._translate.instant("services.chat.new_voice_note", { name });
  }
}
