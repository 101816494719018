import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PATHS } from "@models";

@Component({
  templateUrl: "./create-account-dialog.component.html",
})
export class CreateAccountDialogComponent {
  constructor(
    private readonly _dialogRef: MatDialogRef<CreateAccountDialogComponent>,
    private readonly _router: Router
  ) {}

  onClick(): void {
    this._router.navigateByUrl(PATHS.CreateProfile);
    this._dialogRef.close(true);
  }
}
