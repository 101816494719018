import { Enum, Enums } from "./enum.type";
import { CompleteProfileResponse } from "@core/api/api-client";

export const CompleteProfileResponses = new Enums([
  new Enum({
    key: CompleteProfileResponse.NoResponse,
    name: "No Response",
    route: [""],
    color: "red-600",
  }),
  new Enum({
    key: CompleteProfileResponse.TakeAction,
    name: "Take Action",
    route: [""],
    color: "orange-600",
  }),
  new Enum({
    key: CompleteProfileResponse.Busy,
    name: "Busy",
    route: [""],
    color: "light-green-600",
  }),
  new Enum({
    key: CompleteProfileResponse.NotInterested,
    name: "Not Interested",
    route: [""],
    color: "red-600",
  }),
]);
