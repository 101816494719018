import { ErrorHandler, Injectable } from "@angular/core";
import { ModalService, LoggerService } from "./services";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiException } from "./api/api-client";
import { SeverityLevel } from "./services/loggers/severity-level";

function originalError(error: any): any {
  while (error && error.originalError) {
    error = error.originalError;
  }
  return error;
}

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private readonly _logger: LoggerService,
    private readonly _modal: ModalService
  ) {}

  handleError(error: Error): void {
    if (navigator && !navigator.onLine) {
      this._modal.snackbar("No Internet Connection");
      return;
    }

    if (ApiException.isApiException(error)) {
      this._log(error, SeverityLevel.Warning, "Unhandled API Exception");
    }

    if (error instanceof HttpErrorResponse) {
      this._log(error, SeverityLevel.Warning, "Unhandled HttpErrorResponse");
      return;
    }

    this._log(error, SeverityLevel.Critical, "ErrorHandlerService Unhandled Exception");
  }

  private _log(error: Error, level: SeverityLevel, type: string): void {
    this._logger.logException(error, level, { type });

    const original = originalError(error);
    if (original !== error) {
      this._logger.logException(original, level, { type: "Original Error" });
    }
  }
}
