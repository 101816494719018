import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  template: ` <h1 mat-dialog-title class="text-gray-900">
      {{ "chat_not_available.title" | translate }}
    </h1>

    <div mat-dialog-content class="m-y-8 text-gray-800 f-s-16">
      {{ "chat_not_available.desc" | translate }}<br />

      <ul>
        <li>
          {{ "chat_not_available.email" | translate }}:
          <a href="mailto:support@elharefa.com">support@elharefa.com</a>
        </li>
        <li>
          {{ "chat_not_available.phone" | translate }}:
          <a href="tel:+201228375352">+20 122 837 5352</a>
        </li>
      </ul>

      {{ "chat_not_available.thanks" | translate }}<br />
    </div>

    <div mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="true"
        aria-label="Cancel"
      >
        {{ "confirm_dialog.ok" | translate }}
      </button>
    </div>`,
})
export class ChatNotAvailableDialogComponent {
  constructor(public readonly dialogRef: MatDialogRef<ChatNotAvailableDialogComponent>) {}
}
