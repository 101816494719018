import { FormGroup, ValidatorFn } from "@angular/forms";

export function MatchValidator(
  controlName: string,
  matchingControlName: string,
  errorName = "isMatch"
): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.getError(errorName)) {
      return null;
    }

    matchingControl.setErrors(
      control.value !== matchingControl.value ? { [errorName]: true } : null
    );

    return null;
  };
}

export function GreaterValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.greaterValidator) {
      return null;
    }

    if (control.value < matchingControl.value) {
      control.setErrors({ greaterValidator: true });
    } else {
      control.setErrors(null);
    }

    return null;
  };
}
