import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { IToastConfig, IToastParam, ToastVariant } from "./toast.types";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(
    private readonly _toastr: ToastrService,
    private readonly _translate: TranslateService
  ) {}

  show(
    message?: string,
    title?: string,
    variant?: ToastVariant,
    config?: IToastConfig
  ): void {
    this._toastr.show(message, title, config, variant);
  }

  showInLocal({ config, message, title, variant }: IToastParam): void {
    this._toastr.show(
      this._translate.instant(message.token, message.interpolateParams),
      this._translate.instant(title.token, title.interpolateParams),
      config,
      variant
    );
  }
}
