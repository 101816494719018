import { Address, LoginProvider } from "@core/api/api-client";
import { Countries } from "@models/enums/country";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

export const addressToString = (address: Address, full = false): string => {
  if (!address) {
    return "---";
  }
  const country = Countries.get(address.country).name;

  let str = "";

  if (full) {
    str += address.street ? `${address.street}, ` : "";
    str += address.city ? `${address.city}, ` : "";
  }

  return address.state ? str + `${address.state}, ${country}` : str + country;
};

export const budgetToString = (from: number, to: number, currency: string): string => {
  return !from && !to
    ? "N/A"
    : to === from
    ? `${to} ${currency}`
    : `${from} - ${to} ${currency}`;
};

export function parseProvider(provider: string): LoginProvider {
  if (provider.toLowerCase() === "facebook") {
    return LoginProvider.Facebook;
  }

  if (provider.toLowerCase() === "google") {
    return LoginProvider.Google;
  }

  throw new Error(`Unexpected login provider @${provider}`);
}

export function toProviderId(provider: LoginProvider): string {
  switch (provider) {
    case LoginProvider.Google:
      return GoogleLoginProvider.PROVIDER_ID;
    case LoginProvider.Facebook:
      return FacebookLoginProvider.PROVIDER_ID;
    default:
      throw new Error(`Unexpected login provider @${provider}`);
  }
}
