import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser, Location } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Langs } from "../settings/settings.types";
import { isLangRoutePrefix, langToRoute } from "../settings/utils";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SeoService implements OnDestroy {
  readonly #destroy$ = new Subject<void>();
  readonly #dom: Document;
  readonly #isBrowser: boolean;
  #canonicalLink: HTMLLinkElement;
  #alternateLinks: HTMLLinkElement[] = [];

  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _translate: TranslateService,
    @Inject(DOCUMENT) readonly document: Document,
    @Inject(PLATFORM_ID) readonly platformId: Object
  ) {
    this.#isBrowser = isPlatformBrowser(platformId);
    this.#dom = document;

    if (this.#isBrowser) {
      this._createAlternateLink();
      this._createCanonicalLink();
    }
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  trackCanonicalChanges(): void {
    if (!this.#isBrowser) {
      return;
    }

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.#destroy$)
      )
      .subscribe(() => {
        this.#canonicalLink.setAttribute("href", this._generateCanonicalUrl());
        this._alternateUrls().forEach((url, index) =>
          this.#alternateLinks[index].setAttribute("hreflang", url)
        );
      });
  }

  private _alternateUrls(): string[] {
    return Langs.filter((l) => l !== this._translate.currentLang)
      .map(langToRoute)
      .map((prefix) => {
        const currPath = this._location.path();

        if (!currPath) {
          return `${this.#dom.location.origin}/${prefix}`;
        }

        const currPrefix = currPath.substr(1, 2);

        const p = isLangRoutePrefix(currPrefix)
          ? `/${prefix}${currPath.substr(3)}`
          : `/${prefix}${currPath}`;

        return `${this.#dom.location.origin}${p}`;
      });
  }

  private _createAlternateLink(): void {
    this._alternateUrls().forEach((url, index) => {
      this.#alternateLinks[index] = this.#dom.createElement("link");
      this.#alternateLinks[index].setAttribute("rel", "alternate");
      this.#alternateLinks[index].setAttribute("hreflang", url);

      this.#dom.head.appendChild(this.#alternateLinks[index]);
    });
  }

  private _generateCanonicalUrl(): string {
    return this.#dom.location.origin + this._location.path();
  }

  private _createCanonicalLink(): void {
    this.#canonicalLink = this.#dom.createElement("link");
    this.#canonicalLink.setAttribute("rel", "canonical");
    this.#canonicalLink.setAttribute("href", this._generateCanonicalUrl());

    this.#dom.head.appendChild(this.#canonicalLink);
  }
}
