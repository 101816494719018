import { Enum, Enums } from "@models/enums/enum.type";
import { BankTransactionType } from "@core/api/api-client";

export const BankTransactionTypes = new Enums([
  new Enum({
    key: BankTransactionType.CashTransfer,
    name: "bank_transaction.cash",
    route: ["cash_transfer"],
    color: "red-400",
  }),
  new Enum({
    key: BankTransactionType.Salary,
    name: "bank_transaction.salary",
    route: ["salary"],
    color: "red-400",
  }),
  new Enum({
    key: BankTransactionType.Pension,
    name: "bank_transaction.pension",
    route: ["pension"],
    color: "red-400",
  }),
  new Enum({
    key: BankTransactionType.Prepaid,
    name: "bank_transaction.prepaid",
    route: ["prepaid"],
    color: "red-400",
  }),
  new Enum({
    key: BankTransactionType.CreditCard,
    name: "bank_transaction.credit_card",
    route: ["credit_card"],
    color: "red-400",
  }),
]);
