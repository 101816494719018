export enum RecorderState {
  Initializing = "Initializing",
  Initialized = "Initialized",
  Recording = "Recording",
  Paused = "Paused",
  Stopping = "Stopping",
  Stopped = "Stopped",
}

export enum RecorderErrors {
  UserConsentFailed = "UserConsentFailed",
  RecorderTimeout = "RecorderTimeout",
  AlreadyRecording = "AlreadyRecording",
}
