import { Pipe, PipeTransform } from "@angular/core";
import { Address } from "@core/api/api-client";
import { addressToString } from "@core/utils";

@Pipe({ name: "addressToString" })
export class AddressToStringPipe implements PipeTransform {
  transform(address: Address): string {
    return addressToString(address);
  }
}
