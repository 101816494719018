import { Pipe, PipeTransform } from "@angular/core";
import { NameVm } from "@core/api/api-client";

export const nameToString = (name: NameVm): string =>
  !name ? "" : `${name.firstName} ${name.lastName}`;

@Pipe({ name: "nameToString" })
export class NameToStringPipe implements PipeTransform {
  transform(name: NameVm): string {
    return nameToString(name);
  }
}
