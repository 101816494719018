<h1 mat-dialog-title class="text-center text-gray-800">
  <mat-icon svgIcon="chevron-right"></mat-icon>
  {{ data.title }}
</h1>

<div mat-dialog-content class="m-y-8">
  {{ data.message }}
</div>

<div mat-dialog-actions fxLayoutAlign="center">
  <button mat-flat-button color="primary" aria-label="close" mat-dialog-close>
    {{ "modal_dialog.ok" | translate }}
  </button>
</div>
