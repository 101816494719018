import { Enum, Enums } from "@models/enums/enum.type";
import { TransactionType } from "@core/api/api-client";

export const TransactionTypes = new Enums([
  new Enum({
    key: TransactionType.Payment,
    name: "transaction_type.cash_in",
    route: ["cash-in"],
    color: "green-400",
  }),
  new Enum({
    key: TransactionType.Earning,
    name: "transaction_type.earning",
    route: ["earning"],
    color: "blue-400",
  }),
  new Enum({
    key: TransactionType.Payout,
    name: "transaction_type.cash_out",
    route: ["cash-out"],
    color: "red-400",
  }),
  new Enum({
    key: TransactionType.Tips,
    name: "transaction_type.tips",
    route: ["tips"],
    color: "yellow-400",
  }),
  new Enum({
    key: TransactionType.Tax,
    name: "Tax",
    route: ["tax"],
    color: "gray-400",
  }),
  new Enum({
    key: TransactionType.Fees,
    name: "Fees",
    route: ["fees"],
    color: "gray-400",
  }),
]);
