export const environment = {
  apiUrl: "https://api.elharefa.com",
  production: true,
  googleClientId:
    "956404570172-34sbu08j0k7ofrmi4mvjok9mujddr1ar.apps.googleusercontent.com",
  facebookAppId: "700340387420581",
  paymob: "https://accept.paymobsolutions.com/api/acceptance/iframes/75432",
  appInsights: {
    connection:
      "InstrumentationKey=03a4b00c-b260-4ec6-bd8a-cc3890e8a8eb;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/",
  },
  recaptcha: {
    v2: "6LeXUd8ZAAAAACzaDK1CohsqH8CqbtFHrkghtMTN",
  },
};
