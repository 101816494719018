import { EventEmitter, Injectable } from "@angular/core";
import { RecorderErrors, RecorderState } from "./audio-recorder.models";

@Injectable()
export class AudioRecorder {
  private _recorder: MediaRecorder;
  private _chunks: Blob[] = [];
  private _recorderState = RecorderState.Initializing;
  private _recorderEnded = new EventEmitter<Blob>();
  public recorderError = new EventEmitter<RecorderErrors>();

  get recorderState(): RecorderState {
    return this._recorderState;
  }

  record(): void {
    if (this._recorderState === RecorderState.Recording) {
      this.recorderError.emit(RecorderErrors.AlreadyRecording);
      return;
    }

    if (this._recorderState === RecorderState.Paused) {
      this.resume();
      return;
    }

    this._recorderState = RecorderState.Initializing;

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      this._recorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
      this._recorderState = RecorderState.Initialized;

      this._recorder.ondataavailable = (event: BlobEvent) => {
        this._chunks.push(event.data);
      };

      this._recorder.onstop = () => {
        const blob = new Blob(this._chunks, { type: "audio/webm" });
        this._recorderEnded.emit(blob);
        this.clear();
      };

      this._recorder.start();
      this._recorderState = RecorderState.Recording;
    });
  }

  /** Pauses the recording. */
  pause(): void {
    if (this._recorderState === RecorderState.Recording) {
      this._recorder.pause();
      this._recorderState = RecorderState.Paused;
    }
  }

  /** Resumes recording if it paused. */
  resume(): void {
    if (this._recorderState === RecorderState.Paused) {
      this._recorder.resume();
      this._recorderState = RecorderState.Recording;
    }
  }

  stop(outputFormat: "WebmBlobUrl"): Promise<string>;
  stop(outputFormat: "WebmBlob"): Promise<Blob>;
  stop(outputFormat: "WebmBlobUrl" | "WebmBlob"): Promise<Blob | string> {
    this._recorderState = RecorderState.Stopping;

    return new Promise<Blob | string>((resolve, reject) => {
      this._recorderEnded.subscribe({
        next: (blob) => {
          this._recorderState = RecorderState.Stopped;

          if (outputFormat === "WebmBlob") {
            resolve(blob);
          }

          if (outputFormat === "WebmBlobUrl") {
            const audioURL = URL.createObjectURL(blob);
            resolve(audioURL);
          }
        },
        error: (_) => {
          this.recorderError.emit(RecorderErrors.RecorderTimeout);
          reject(RecorderErrors.RecorderTimeout);
        },
      });

      this._recorder.stop();
    }).catch(() => {
      this.recorderError.emit(RecorderErrors.UserConsentFailed);
      return Promise.reject(RecorderErrors.UserConsentFailed);
    });
  }

  /** Clear record. */
  clear() {
    this._recorder?.stream.getAudioTracks().forEach((t) => t.stop());
    this._recorder = null;
    this._chunks = [];
    this._recorderState = RecorderState.Initializing;
  }
}
