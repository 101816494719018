import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PageHeaderComponent } from "./page-header.component";
import { BreadcrumbModule } from "@widgets/breadcrumb/breadcrumb.module";

@NgModule({
  imports: [CommonModule, MatIconModule, BreadcrumbModule, MatTooltipModule],
  declarations: [PageHeaderComponent],
  exports: [PageHeaderComponent],
})
export class PageHeaderModule {}
