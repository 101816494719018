import { Enum, Enums } from "./enum.type";
import { JobStatus } from "@core/api/api-client";

export const JobStates = new Enums([
  new Enum({
    key: JobStatus.Pending,
    name: "job_state.pending",
    route: ["pending"],
    color: "orange-600",
  }),
  new Enum({
    key: JobStatus.Rejected,
    name: "job_state.rejected",
    route: ["rejected"],
    color: "red-600",
  }),
  new Enum({
    key: JobStatus.Accepted,
    name: "job_state.accepted",
    route: ["accepted"],
    color: "light-green-600",
  }),
  new Enum({
    key: JobStatus.Active,
    name: "job_state.active",
    route: ["active"],
    color: "amber-500",
  }),
  new Enum({
    key: JobStatus.Deactivated,
    name: "job_state.deactivated",
    route: ["deactivated"],
    color: "blue-gray-600",
  }),
  new Enum({
    key: JobStatus.Won,
    name: "job_state.success",
    route: ["success"],
    color: "green-600",
  }),
  new Enum({
    key: JobStatus.Lost,
    name: "job_state.lost",
    route: ["lost"],
    color: "red-600",
  }),
  new Enum({
    key: JobStatus.NotCompleted,
    name: "job_state.not_completed",
    route: ["not-completed"],
    color: "gray-700",
  }),
  new Enum({
    key: JobStatus.Old,
    name: "job_state.old",
    route: ["old"],
    color: "gray-900",
  }),
  new Enum({
    key: JobStatus.OldInProcess,
    name: "job_state.old",
    route: ["old-in-process"],
    color: "gray-900",
  }),
]);
