import {
  ForbiddenProblemDetails,
  NotFoundProblemDetails,
  ValidationProblemDetails,
} from "./models/problem-details";
import {
  ApiError,
  ConflictApiError,
  NotFoundApiError,
  UnknownError,
  ValidationApiError,
} from "./models/api-error";
import { ValidationDescriber, ValidationErrors } from "./models/error-describer";
import { Injectable } from "@angular/core";
import { ApiException } from "./api-client";
import { OverriddenMessages } from "./models/overridden-messages";
import { ProblemTitles } from "./models/problem-titles";
import { StatusCodes } from "./models/status-codes";
import { ModalService } from "../services/modals/modal.service";
import { LoggerService } from "../services/loggers/logger.service";
import { SeverityLevel } from "../services/loggers/severity-level";
import { ToastService } from "../services/modals/toast.service";
import { isEmptyObject, isString } from "@core/utils/utils";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, Observable } from "rxjs";
import { FormGroup } from "@angular/forms";

@Injectable()
export class ApiErrorHandlerService {
  constructor(
    private readonly _modal: ModalService,
    private readonly _toast: ToastService,
    private readonly _translate: TranslateService,
    private readonly _logger: LoggerService
  ) {}

  required(form: FormGroup): void {
    form.markAllAsTouched();

    const invalids = [];
    for (const name in form.controls) {
      if (form.controls[name].invalid) {
        invalids.push(name);
      }
    }
    this._logger.logTrace("Invalid form: ", {
      values: form.value,
      errors: form.errors,
      invalid: { state: form.invalid, invalids },
    });

    this._modal.snackbarInLocal({ token: "validations.invalid_form" });
  }

  handle<T = any>(
    error: ApiException,
    messages?: OverriddenMessages<T>
  ): Observable<never> {
    const err = this.get(error, messages);
    if (!err) {
      return EMPTY;
    }

    switch (err.type) {
      case "unknown":
      case "not_found":
        this._modal.snackbar(err.value);
        break;
      case "conflict":
        err.value.forEach((e) => this._toast.show(e.description, this._errorKeyword()));
        break;
      case "validation":
        Object.keys(err.value)
          .map((key) => [key, err.value[key]] as [string, ValidationDescriber[]])
          .forEach(([key, arr]) =>
            arr.forEach((e) =>
              this._toast.show(`${key}: ${e.description}`, this._errorKeyword())
            )
          );
        break;
    }

    return EMPTY;
  }

  show<T = any>(err: ApiError<T>): Observable<never> {
    switch (err.type) {
      case "unknown":
      case "not_found":
        this._modal.snackbar(err.value);
        break;
      case "conflict":
        err.value.forEach((e) => this._toast.show(e.description, this._errorKeyword()));
        break;
      case "validation":
        Object.keys(err.value)
          .map((key) => [key, err.value[key]] as [string, ValidationDescriber[]])
          .forEach(([key, arr]) =>
            arr.forEach((e) =>
              this._toast.show(`${key}: ${e.description}`, this._errorKeyword())
            )
          );
        break;
    }

    return EMPTY;
  }

  get<T = any>(
    error: ApiException,
    messages?: OverriddenMessages<T>
  ): ApiError<T> | null {
    this._logger.logTrace("onApiErrorHandler ", { err: error });

    if (!error) {
      return null;
    }

    if (!ApiException.isApiException(error)) {
      this._logger.logException(
        new Error("API Error: Unknown error"),
        SeverityLevel.Error,
        { error }
      );
      return this._unknown();
    }

    if ((error.status >= 200 && error.status <= 299) || error.status >= 500) {
      return null;
    }

    const response = this._parse(error.response);

    if (!response) {
      return this._unknown();
    }

    switch (error.status) {
      case StatusCodes.NotFound:
        return this._notFound(response as NotFoundProblemDetails, messages);
      case StatusCodes.Forbidden:
        return this._conflict(response as ForbiddenProblemDetails, messages);
      case StatusCodes.UnprocessableEntity:
        return this._validation(response as ValidationProblemDetails<T>, messages);
      case StatusCodes.BadRequest:
      case StatusCodes.Unauthorized:
        return null;
      default:
        this._logger.logException(
          new Error("ApiErrorHandlerService | Unknown unhandled"),
          SeverityLevel.Error,
          { error }
        );
        return this._unknown();
    }
  }

  private _notFound<T>(
    response: NotFoundProblemDetails,
    messages?: OverriddenMessages<T>
  ): NotFoundApiError | UnknownError {
    if (response.title === ProblemTitles.EntityNotFounded) {
      return {
        type: "not_found",
        value: messages?.NotFound
          ? messages.NotFound(response.extra.property, response.extra.key)
          : this._translate.instant("errors.entity_not_founded", response.extra),
      };
    }

    this._logger.logException(
      response.title === ProblemTitles.EndpointNotFounded
        ? new Error("Endpoint 404 error")
        : new Error("Unexpected 404 error"),
      SeverityLevel.Error,
      { response }
    );

    return this._unknown();
  }

  private _conflict<T>(
    response: ForbiddenProblemDetails,
    messages?: OverriddenMessages<T>
  ): ConflictApiError | UnknownError {
    if (!response.errors || response.errors.length < 1) {
      return this._unknown();
    }

    if (!messages?.Conflict) {
      return { type: "conflict", value: response.errors };
    }

    return {
      type: "conflict",
      value: response.errors.map((err) => {
        const override = messages.Conflict[err.code];
        return override ? { ...err, description: override } : err;
      }),
    };
  }

  private _validation<T>(
    response: ValidationProblemDetails<T>,
    messages?: OverriddenMessages<T>
  ): ValidationApiError<T> | UnknownError {
    if (!response.errors || isEmptyObject(response.errors)) {
      return this._unknown();
    }

    // no overrides
    if (!messages?.Validation) {
      return {
        type: "validation",
        value: response.errors as ValidationErrors<T>,
      };
    }

    const objects = Object.keys(response.errors).reduce((obj, key) => {
      const errors: ValidationDescriber[] = response.errors[key];

      if (!errors) {
        return obj;
      }

      const override = messages.Validation[key];
      if (override) {
        Object.keys(override)
          .map((code) => [code, override[code]])
          .filter(([, value]) => !!value)
          .forEach(([code, value]) => {
            const toOverride = errors.find((e) => e.code === code);
            if (toOverride) {
              toOverride.description = value;
            }
          });
      }

      if (errors) {
        obj[key] = errors;
      }

      return obj;
    }, {});

    if (isEmptyObject(objects)) {
      return this._unknown();
    }

    return {
      type: "validation",
      value: objects as ValidationErrors<T>,
    };
  }

  private _unknown(): UnknownError {
    return { type: "unknown", value: this._translate.instant("errors.unknown") };
  }

  private _errorKeyword(): string {
    return this._translate.instant("keywords.error");
  }

  private _parse(response: string | unknown): unknown | null {
    try {
      return !isString(response) ? response : JSON.parse(response as string);
    } catch (ex) {
      this._logger.logException(ex, SeverityLevel.Critical, {
        message: "Error while parse ApiException response",
      });
      return null;
    }
  }
}
