import { IAppSettings } from "@core/services";

export const Default: IAppSettings = {
  language: "en-US",
  dir: "ltr",
  theme: "light",
  showHeader: true,
  navPos: "side",
  headerPos: "fixed",
  showUserPanel: true,
  sidenavOpened: true,
  sidenavCollapsed: false,
};
