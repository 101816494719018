import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "h-chat-message-system",
  template: `
    <p class="sender" *ngIf="sender || sendAt">
      <!-- <span [matTooltip]="sender" matTooltipPosition="left">System</span> -->
      <time>{{ sendAt | date: dateFormat }}</time>
    </p>
    <p class="text" *ngIf="message">{{ message }}</p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageSystemComponent {
  /** Message sender */
  @Input() sender: string;

  /** Message sent */
  @Input() message: string;

  /** Message send date */
  @Input() sendAt: Date;

  /** Message send date format, default 'shortTime' */
  @Input() dateFormat = "shortTime";
}
