import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IDialogContent } from "../dialog-content.interface";

@Component({
  template: ` <h1 mat-dialog-title class="text-center text-gray-800">
      <mat-icon svgIcon="chevron-right"></mat-icon>
      {{ data.title }}
    </h1>

    <div mat-dialog-content class="m-y-8" [innerHTML]="data.message"></div>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="true" aria-label="Cancel">
        {{ "confirm_dialog.ok" | translate }}
      </button>
      <button mat-flat-button color="warn" [mat-dialog-close]="false" aria-label="Ok">
        {{ "confirm_dialog.cancel" | translate }}
      </button>
    </div>`,
})
export class ConfirmDialogComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: IDialogContent
  ) {}
}
