import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

/** Chat message component. */
@Component({
  selector: "h-chat-message-file",
  template: `
    <h-chat-message-text
      [message]="message"
      [sender]="sender"
      [sendAt]="sendAt"
      [dateFormat]="dateFormat"
    >
      {{ message }}
    </h-chat-message-text>

    <h-chat-message-files-preview [files]="files"></h-chat-message-files-preview>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageFileComponent {
  /** Message sender */
  @Input() message: string;

  /** Message sender */
  @Input() sender: string;

  /** Message send date */
  @Input() sendAt: Date;

  /** Message send date format, default 'shortTime' */
  @Input() dateFormat = "shortTime";

  /** Message files path */
  @Input() files: string[];
}
