import { Injectable } from "@angular/core";
import { ILoggerService } from "./logger.interface";
import { SeverityLevel } from "./severity-level";

@Injectable()
export class LoggerService implements ILoggerService {
  logPageView(name?: string, url?: string): void {
    console.log({ name: "logPageView", pageView: { name, uri: url } });
  }

  logEvent(name: string, properties?: Record<string, any>): void {
    console.log({
      name: "logEvent",
      event: { name, properties },
    });
  }

  logMetric(name: string, average: number, properties?: Record<string, any>): void {
    console.log({ name: "metric", metric: { name, average, properties } });
  }

  logTrace(message: string, properties?: Record<string, any>): void {
    console.group(`@Trace ~ ${message}`);
    if (properties) {
      if (properties.res && properties.req) {
        console.dir(properties.req);
        console.dir(properties.res);
      } else {
        console.dir(properties);
      }
    }

    console.groupEnd();
  }

  logException(
    exception: Error,
    severityLevel: SeverityLevel,
    properties?: Record<string, any>
  ): void {
    console.group(`@Error ${exception.name}`);
    console.error(exception);
    if (properties) {
      if (properties.req) {
        console.log(properties);
      }
    }
    console.groupEnd();
  }
}
