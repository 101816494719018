import { Direction } from "@angular/cdk/bidi";
import { Language } from "@core/api/api-client";
import { Lang, LangRoute } from "@core/services/settings/settings.types";

export function getLangDir(lang: Lang): Direction {
  return lang === "en-US" ? "ltr" : "rtl";
}

export function languageToRoute(lang: Language): LangRoute {
  switch (lang) {
    case Language.Ar:
      return "ar";
    default:
      return "en";
  }
}

export function toLang(lang: Language): Lang {
  switch (lang) {
    case Language.Ar:
      return "ar-EG";
    default:
      return "en-US";
  }
}

export function toLanguage(lang: Lang): Language {
  switch (lang) {
    case "en-US":
      return Language.En;
    case "ar-EG":
      return Language.Ar;
  }
}

export function langToRoute(lang: Lang): LangRoute {
  switch (lang) {
    case "ar-EG":
      return "ar";
    default:
      return "en";
  }
}

export function routeToLang(lang: LangRoute): Lang {
  switch (lang) {
    case "ar":
      return "ar-EG";
    default:
      return "en-US";
  }
}

/** Returns true if @val is valid lang prefix */
export function isLangRoutePrefix(val: string): boolean {
  return val === "en" || val === "ar";
}
