import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthToken } from "../models/token";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import { AUTH_TOKEN_KEY } from "@core/tokens-key";

/**
 * Service that allows you to manage authentication token - get, set,
 * clear and also listen to token changes over time.
 */
@Injectable()
export class AuthTokenService {
  private readonly _token$ = new BehaviorSubject<AuthToken | null>(null);

  constructor(private readonly _storage: LocalStorageService) {
    this._publishStoredToken();
  }

  /** Token observable it publish when token changes. */
  get token$(): Observable<AuthToken | null> {
    return this._token$;
  }

  /** Returns the current token from localStorage */
  get token(): AuthToken | null {
    const val = this._storage.get(AUTH_TOKEN_KEY) as string;
    return !!val ? new AuthToken(val) : null;
  }

  /** Sets token to storage */
  set(token: AuthToken): void {
    this._storage.set(AUTH_TOKEN_KEY, token.value);
    this._publishStoredToken();
  }

  /** Removes the token and published token value */
  clear(): void {
    this._storage.remove(AUTH_TOKEN_KEY);
    this._publishStoredToken();
  }

  private _publishStoredToken(): void {
    const token = this.token;
    this._token$.next(token);
  }
}
