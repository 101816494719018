import { Direction } from "@angular/cdk/bidi";

export type IAppSettings = {
  readonly language: Lang;
  readonly dir: Direction;
  readonly theme: "light" | "dark";
  readonly navPos: "side" | "top";
  readonly showHeader: boolean;
  readonly headerPos: "fixed" | "static" | "above";
  readonly showUserPanel: boolean;
  readonly sidenavOpened: boolean;
  readonly sidenavCollapsed: boolean;
};

export type Lang = "en-US" | "ar-EG";
export type LangRoute = "en" | "ar";

export const Langs: Lang[] = ["en-US", "ar-EG"];
export const LangsRoute: LangRoute[] = ["en", "ar"];
