import { APP_INITIALIZER } from "@angular/core";
import { TranslateLoaderService } from "./translation-loader.service";
import { APP_BASE_HREF } from "@angular/common";
import { IdentityManager } from "@core/auth";
import { isLangRoutePrefix, languageToRoute } from "@core/services/settings/utils";

export const InitializerProviders = [
  /** Initialize Base Href to current language */
  {
    provide: APP_BASE_HREF,
    useFactory(identityManager: IdentityManager): string {
      const lang = location?.pathname?.split("/")[1];

      // if prefix provided use it.
      if (lang && isLangRoutePrefix(lang)) {
        return lang === "ar" ? lang : "en";
      }

      // if user is authenticated use his preferences
      if (identityManager.isAuthenticated) {
        return languageToRoute(identityManager.user.lang);
      }

      // else use browser lang
      return navigator.language.match(/^ar-?/) ? "ar" : "en";
    },
    deps: [IdentityManager],
  },
  /** Initialize translation based on the current language */
  {
    provide: APP_INITIALIZER,
    useFactory(loader: TranslateLoaderService) {
      return () => loader.load();
    },
    deps: [TranslateLoaderService],
    multi: true,
  },
];
