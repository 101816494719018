import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { IdentityManager } from "../services/identity-manager.service";
import { PATHS, QUERY_PARAMETER_NAMES } from "@models";

@Injectable()
export class FreelancerGuard implements CanActivate, CanLoad {
  constructor(private _identityManager: IdentityManager, private _router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const returnUrl = segments.reduce(
      (path, currentSegment) => `${path}/${currentSegment.path}`,
      ""
    );

    return this._handle(returnUrl);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._handle(state.url);
  }

  private _handle(returnUrl: string): Observable<boolean> {
    return this._identityManager.user$.pipe(
      take(1),
      map((user) => {
        // is authorized
        if (!user) {
          this._router.navigate([PATHS.Login], {
            queryParams: {
              [QUERY_PARAMETER_NAMES.ReturnUrl]: returnUrl,
            },
          });

          return false;
        }

        // is freelancer
        if (user.isFreelancer) {
          return true;
        }

        this._router.navigateByUrl(PATHS.Error403);
        return false;
      })
    );
  }
}
