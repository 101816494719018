import { Enum, Enums } from "@models/enums/enum.type";
import { PayoutIssuer } from "@core/api/api-client";

export const CashOutIssuers = new Enums([
  new Enum({
    key: PayoutIssuer.Aman,
    name: "cash_out_issuer.aman",
    route: ["aman"],
    color: "green-400",
  }),
  new Enum({
    key: PayoutIssuer.Vodafone,
    name: "cash_out_issuer.vodafone",
    route: ["vodafone"],
    color: "red-400",
  }),
  new Enum({
    key: PayoutIssuer.Etisalat,
    name: "cash_out_issuer.etisalat",
    route: ["etisalat"],
    color: "blue-400",
  }),
  new Enum({
    key: PayoutIssuer.Orange,
    name: "cash_out_issuer.orange",
    route: ["orange"],
    color: "orange-400",
  }),
  new Enum({
    key: PayoutIssuer.BankWallet,
    name: "cash_out_issuer.bank_wallet",
    route: ["bank_wallet"],
    color: "black-400",
  }),
  new Enum({
    key: PayoutIssuer.BankCard,
    name: "cash_out_issuer.bank_card",
    route: ["bank_card"],
    color: "gray-400",
  }),
]);
