import { environment } from "@env/environment";
import { LoggerService } from "./logger.service";
import { AppLoggerService } from "./app-logger.service";

export const LOGGER_PROVIDER = [
  {
    provide: LoggerService,
    useClass: environment.production ? AppLoggerService : LoggerService,
  },
];
