const ACCOUNT_ROOT = "/account";
const FREELANCERS_ROOT = "/freelancers";
const JOBS_ROOT = "/jobs";
const OFFICE_ROOT = "/office";
const CLIENT_ROOT = "/client";
const FREELANCER_ROOT = "/freelancer";

export const PATHS = {
  Home: "/",
  Payment: "/payment/info",
  Contract: "/contract",
  Review: "/contract/review",
  Wallet: `/wallet`,
  OfficeRoot: OFFICE_ROOT,
  Office: {
    Dashboard: `${OFFICE_ROOT}/dashboard`,
    Freelancers: `${OFFICE_ROOT}/freelancers`,
    Clients: `${OFFICE_ROOT}/clients`,
    Jobs: `${OFFICE_ROOT}/jobs`,
    Transactions: `${OFFICE_ROOT}/transactions`,
    Reviews: `${OFFICE_ROOT}/reviews`,
  },
  ClientRoot: CLIENT_ROOT,
  Client: {
    Dashboard: `${CLIENT_ROOT}/dashboard`,
  },
  FreelancerRoot: FREELANCER_ROOT,
  Freelancer: {
    Dashboard: `${FREELANCER_ROOT}/dashboard`,
  },
  AccountSettings: "/settings/general",
  SecuritySettings: "/settings/security",
  EmailSubscriptionSettings: "/settings/email-preferences",
  CreateProfile: "/profile/create",
  FreelancerDashboard: "/freelancer/dashboard",
  // Jobs
  JobsRoot: JOBS_ROOT,
  Jobs: `${JOBS_ROOT}`,
  PostJob: "/post-job",
  OpenPostJob: "/hire",
  // Freelancers
  FreelancersRoot: FREELANCERS_ROOT,
  Freelancers: `${FREELANCERS_ROOT}`,
  // Auth
  AuthRoot: ACCOUNT_ROOT,
  Login: `${ACCOUNT_ROOT}/login`,
  Logout: `${ACCOUNT_ROOT}/logout`,
  Register: `${ACCOUNT_ROOT}/register`,
  RegisterConfirmation: `${ACCOUNT_ROOT}/register-confirmation`,
  ConfirmEmail: `${ACCOUNT_ROOT}/confirmation`,
  ConfirmEmailChange: `${ACCOUNT_ROOT}/change-confirmation`,
  ForgetPassword: `${ACCOUNT_ROOT}/forget-password`,
  ResetPassword: `${ACCOUNT_ROOT}/reset-password`,
  CompleteRegistration: `${ACCOUNT_ROOT}/complete-registration`,
  ResendEmailConfirmation: `${ACCOUNT_ROOT}/resend-email-confirmation`,
  // Errors
  Error500: "/error/500",
  Error403: "/error/403",
  Error404: "/error/404",
  // Contact
  Terms: "/terms",
  PrivacyPolicy: "/privacy-policy",
  HowItWorks: "/how-it-works",
  ContactUs: "/contact",
  About: "/about",
  ClientFaqs: "/employers-faq",
  FreelancerFaqs: "/freelancers-faq",
  Press: "https://blog.elharefa.com/press/",
  Why: "",
  Partners: "",
  OurClients: "https://blog.elharefa.com/success-stories/",
  Testimonials: "https://blog.elharefa.com/reviews/",
  Blog: "https://blog.elharefa.com/",
  Community: "https://www.facebook.com/groups/TheFreelancersHub",
  Facebook: "https://www.facebook.com/elharefaco",
  Twitter: "https://twitter.com/elharefaco",
  Instagram: "https://www.instagram.com/elharefaco",
  LinkedIn: "https://www.linkedin.com/company/elharefaco",
  HelpCenter: "https://support.elharefa.com/",
};

const RETURN_URL_TYPE = "returnUrl";
const MESSAGE_URL_TYPE = "message";

export const QUERY_PARAMETER_NAMES = {
  ReturnUrl: RETURN_URL_TYPE,
  Message: MESSAGE_URL_TYPE,
};
