import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ThemePalette } from "@angular/material/core";
import { AudioRecorder } from "@core/services";
import { UploadedFile } from "@widgets/chat/file";
import Timeout = NodeJS.Timeout;
import { featuresConstants } from "@core/features.constants";

/**
 * Chat form component.
 *
 * Show a message form with a send message button.
 *
 * ```ts
 * <h-chat-form showButton="true" buttonIcon="paper-plane">
 * </h-chat-form>
 * ```
 *
 * When `[dropFiles]="true"` handles files drag&drop with a file preview.
 *
 * Drag & drop available for files and images:
 *
 * New message could be tracked outside by using `(send)` output.
 *
 * ```ts
 * <h-chat-form (send)="onNewMessage($event)">
 * </h-chat-form>
 *
 * // ...
 *
 * onNewMessage({ message: string, files: any[] }) {
 *   this.service.sendToServer(message, files);
 * }
 * ```
 */
@Component({
  selector: "h-chat-form",
  templateUrl: "./chat-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFormComponent {
  readonly chatAvailable = featuresConstants.chatAvailable;
  recordLength = 0;
  recordTimer?: Timeout;
  isRecording = false;
  flash = false;
  inputFocus = false;
  inputHover = false;
  droppedFiles: UploadedFile[] = [];
  imgDropTypes = ["image/png", "image/jpeg", "image/gif"];

  /** Predefined message text */
  @Input() message = "";

  /** Message placeholder text */
  @Input() messagePlaceholder = "Type a message";

  /** Send button icon, shown if `buttonTitle` is empty */
  @Input() buttonIcon = "paper-plane";

  /** Show send button */
  @Input() dropFiles = false;

  /** File drop placeholder text */
  @Input() dropFilePlaceholder = "Drop file to send";

  @Output() text = new EventEmitter<{ message: string; files: File[] }>();

  @Output() voice = new EventEmitter<{ record: Blob; files: File[] }>();

  @Output() onInputFocus = new EventEmitter<void>();

  @HostBinding("class.file-over") fileOver = false;

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _recorder: AudioRecorder
  ) {}

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent): void {
    if (!this.dropFiles) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  onFocus() {
    this.onInputFocus.emit();
    this.inputFocus = true;
  }

  addFiles(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      const file: UploadedFile = files[i];

      const exist = this.droppedFiles.find(
        (f) =>
          f.name == file.name &&
          f.lastModified == file.lastModified &&
          f.size === file.size
      );

      if (exist) {
        continue;
      }

      if (this.imgDropTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = (e) => {
          file.previewUrl = this._domSanitizer.bypassSecurityTrustStyle(
            `url(${e.target.result})`
          );
          this._cd.detectChanges();
        };

        reader.readAsDataURL(file);
      }

      this.droppedFiles.push(file);
    }
  }

  removeFile(file: File): void {
    const index = this.droppedFiles.indexOf(file);
    if (index >= 0) {
      this.droppedFiles.splice(index, 1);
    }
  }

  @HostListener("dragover") onDragOver(): void {
    if (this.dropFiles && !this.fileOver) {
      this.fileOver = true;
    }
  }

  @HostListener("dragleave") onDragLeave(): void {
    if (this.dropFiles && this.fileOver) {
      this.fileOver = false;
    }
  }

  getColor(): ThemePalette {
    if (this.fileOver || this.inputFocus) {
      return "accent";
    }

    // if (this.inputHover) {
    //   return "primary";
    // }

    return "primary";
  }

  sendMessage(): void {
    if (this.droppedFiles.length || String(this.message).trim().length) {
      this.text.emit({ message: this.message, files: this.droppedFiles });
      this.message = "";
      this.droppedFiles = [];
    }
  }

  startRecord(): void {
    const increment = () => {
      this.recordLength += 1;
      this._cd.detectChanges();

      this.recordTimer = setTimeout(increment, 1000);
    };

    this.isRecording = true;
    this._recorder.record();
    this.recordTimer = setTimeout(increment, 1000);
  }

  sendRecord(): void {
    this._recorder.stop("WebmBlob").then((blob) => {
      this.voice.emit({ record: blob, files: this.droppedFiles });
      this.clearRecord();
      this.droppedFiles = [];
    });
  }

  clearRecord(): void {
    if (this.recordTimer) {
      clearTimeout(this.recordTimer);
    }
    this.isRecording = false;
    this.recordLength = 0;
    this._recorder.clear();
  }
}
