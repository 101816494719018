import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminGuard } from "@core/auth";

const AppRoutes: Routes = [
  {
    matcher: (segments) => {
      if (
        segments.length > 0 &&
        (segments[0].path === "en" || segments[0].path === "ar")
      ) {
        const consumed = [segments[0]];
        segments.splice(0, 1);
        return { consumed };
      }

      return { consumed: [] };
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/shell/shell.module").then((m) => m.ShellModule),
      },
      {
        path: "account",
        loadChildren: () =>
          import("@pages/account/account.module").then((m) => m.AccountModule),
      },
      {
        path: "office",
        canLoad: [AdminGuard],
        loadChildren: () =>
          import("./pages/office/office.module").then((m) => m.OfficeModule),
      },
    ],
  },
  { path: "**", redirectTo: "/error/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
