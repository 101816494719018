import { Chat } from "./chat";
import { Observable, ReplaySubject } from "rxjs";
import { combineAll, distinct, map, share } from "rxjs/operators";

export class Chats {
  private readonly _chats = new Array<Chat>();
  private readonly _chats$ = new ReplaySubject<Chat>();

  get items$(): Observable<Chat> {
    return this._chats$.pipe(distinct((chat) => chat.id));
  }

  get unread$(): Observable<number> {
    return this._chats$.pipe(
      map((chat) => chat.unread$),
      combineAll(),
      map((numbers) => numbers.reduce((sum, curr) => sum + curr, 0)),
      share()
    );
  }

  add(chat: Chat) {
    if (this._chats.find((c) => c.id == chat.id)) {
      return;
    }

    this._chats.push(chat);
    this._chats$.next(chat);
  }

  addAll(chats: Chat[]): void {
    if (!chats) {
      return;
    }

    chats.forEach((chat) => {
      if (this._chats.find((c) => c.id == chat.id)) {
        return;
      }

      this._chats.push(chat);
      this._chats$.next(chat);
    });
  }

  find(chatId: number): Chat | undefined {
    return this._chats.find((chat) => chat.id === chatId);
  }

  findByPartner(accountId: string): Chat | undefined {
    return this._chats.find((chat) => chat.members.find((m) => m.id === accountId));
  }
}
