import { Params } from "@angular/router";

export interface IEnum {
  key: number;
  name: string;
  desc?: string;
  route: string[];
  icon?: string;
  color?: string;
}

export class Enum implements IEnum {
  key: number;
  name: string;
  desc?: string;
  route: string[];
  icon?: string;
  color?: string;

  constructor(model: IEnum) {
    this.key = model.key;
    this.name = model.name;
    this.desc = model.desc;
    this.route = model.route;
    this.icon = model.icon;
    this.color = model.color;
  }

  generateRouteUrl(prefix = "", suffix = ""): string {
    return this.route.reduce((prev, curr) => prev + "/" + curr, prefix) + "/" + suffix;
  }

  generateQueryParam(paramName: string): Params {
    const obj: Params = {};
    obj[paramName] = this.route.reduce((prev, curr) => prev + "-" + curr, "");
    return obj;
  }
}

export class Enums {
  constructor(public models: Enum[]) {}

  get keys(): number[] {
    return this.models.map((m) => m.key);
  }

  get names(): string[] {
    return this.models.map((m) => m.name);
  }

  get(key: number | null): Enum | null {
    return typeof key === "number" ? this.models.find((m) => m.key === key) : null;
  }

  getByName(name: string): Enum | null {
    return this.models.find((m) => m.name === name) || null;
  }

  findByRoute(name: string): Enum {
    return !name ? null : this.models.find((m) => m.route.includes(name.toLowerCase()));
  }

  /**
   * Find first model that its route segment contain each @routes and return it
   * or return null when pass empty route or object not founded otherwise.
   * Notes: routes order doesn't matter.
   */
  findBySegments(...routes: (string | null | undefined)[]): Enum | null {
    const _routes = routes.filter((r) => !!r);
    if (!_routes || _routes.length === 0) {
      return null;
    }

    return this.models.find((m) => _routes.every((r) => m.route.includes(r))) || null;
  }
}
