import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { QUERY_PARAMETER_NAMES, PATHS } from "@models";
import { IdentityManager } from "../services/identity-manager.service";
import { ModalService } from "../../services/modals/modal.service";

@Injectable()
export class ClientGuard implements CanActivate, CanLoad {
  constructor(
    private _identityManager: IdentityManager,
    private _router: Router,
    private _modal: ModalService
  ) {}

  canLoad(_route: Route, segments: UrlSegment[]): Observable<boolean> {
    const returnUrl = segments.reduce(
      (path, currentSegment) => `${path}/${currentSegment.path}`,
      ""
    );

    return this._handle(returnUrl);
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._handle(state.url);
  }

  private _handle(returnUrl: string): Observable<boolean> {
    return this._identityManager.user$.pipe(
      take(1),
      map((user) => {
        // is authorized
        if (!user) {
          this._router.navigate([PATHS.Login], {
            queryParams: {
              [QUERY_PARAMETER_NAMES.ReturnUrl]: returnUrl,
            },
          });

          return false;
        }

        // is client
        if (user.isClient) {
          return true;
        }

        if (returnUrl.includes("post-job")) {
          this._modal.snackbarInLocal({ token: "guards.not_authorized_to_post_jobs" });
          return false;
        }

        this._router.navigateByUrl(PATHS.Error403);
        return false;
      })
    );
  }
}
