import { Inject, Injectable, Injector } from "@angular/core";
import { LOCATION_INITIALIZED, APP_BASE_HREF } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { getLangDir, routeToLang } from "../services/settings/utils";
import { LangRoute } from "../services/settings/settings.types";
import { SettingsService } from "@core/services";

@Injectable({
  providedIn: "root",
})
export class TranslateLoaderService {
  constructor(
    private readonly _injector: Injector,
    private readonly _translate: TranslateService,
    private readonly _settings: SettingsService,
    @Inject(APP_BASE_HREF) private readonly _baseHref: LangRoute
  ) {}

  load() {
    return new Promise<any>((resolve: any) => {
      const locationInitialized = this._injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const lang = routeToLang(this._baseHref);
        this._translate.addLangs(["en-US", "ar-EG"]);
        this._translate.setDefaultLang(lang);
        this._translate.use(lang).subscribe({
          complete: () => {
            this._settings.changeDirectionality(lang);
            this._settings.changeOptions({ language: lang, dir: getLangDir(lang) });
            return resolve(null);
          },
        });
      });
    });
  }
}
