import { CanActivate, Router, CanLoad } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PATHS } from "@models";
import { IdentityManager } from "../services/identity-manager.service";
import { ModalService } from "../../services/modals/modal.service";

@Injectable()
export class OpenPostJobGuard implements CanActivate, CanLoad {
  constructor(
    private readonly _identityManager: IdentityManager,
    private readonly _router: Router,
    private readonly _modal: ModalService
  ) {}

  canLoad(): Observable<boolean> | boolean {
    return this._handle();
  }

  canActivate(): Observable<boolean> | boolean {
    return this._handle();
  }

  private _handle(): boolean {
    const user = this._identityManager.user;

    if (!user || user.isClient) {
      return true;
    }

    this._router.navigateByUrl(PATHS.Home).then(() => {
      this._modal.snackbarInLocal({ token: "guards.not_authorized_to_post_jobs" });
    });

    return false;
  }
}
