import { Enum, Enums } from "@models/enums/enum.type";
import { CriteriaType } from "@core/api/api-client";

export const CriteriaTypes = new Enums([
  new Enum({
    key: CriteriaType.Stars,
    name: "criteria_type.stars",
    desc: "criteria_type.stars_desc",
    route: ["stars"],
    color: "yellow-800",
  }),
  new Enum({
    key: CriteriaType.Scale,
    name: "criteria_type.scale",
    desc: "criteria_type.scale_desc",
    route: ["scale"],
    color: "pink-600",
  }),
  new Enum({
    key: CriteriaType.Boolean,
    name: "criteria_type.bool",
    desc: "criteria_type.bool_desc",
    route: ["boolean"],
    color: "red-600",
  }),
  new Enum({
    key: CriteriaType.Text,
    name: "criteria_type.text",
    desc: "criteria_type.text_desc",
    route: ["text"],
    color: "gray-600",
  }),
]);
