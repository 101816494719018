import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { MessageType } from "@core/api/api-client";

/**
 * Chat message component.
 *
 * Multiple message types are available through a `type` property, such as
 * - text - simple text message
 * - file - could be a file preview or a file icon if multiple files are provided grouped files are shown
 * - quote - quotes a message with specific quote styles
 * - map - shows a google map picture by provided [latitude] and [longitude] properties
 *
 * @example
 *
 * Message with attached files:
 * ```html
 * <h-chat-message
 *   type="file"
 *   [files]="[ { url: '...' } ]"
 *   message="Hello world!">
 * </h-chat-message>
 * ```
 *
 * Map message:
 * ```html
 * <h-chat-message
 *   type="map"
 *   [latitude]="53.914"
 *   [longitude]="27.59"
 *   message="Here I am">
 * </h-chat-message>
 * ```
 */
@Component({
  selector: "h-chat-message",
  template: `
    <!--<div class="avatar" [style.background-image]="avatarStyle" *ngIf="!reply">
      <ng-container *ngIf="!avatarStyle">
        {{ getInitials() }}
      </ng-container>
    </div>-->

    <div class="message">
      <ng-container [ngSwitch]="type">
        <h-chat-message-system
          *ngSwitchCase="MessageType.System"
          [message]="message"
          [sender]="sender"
          [sendAt]="sendAt"
          [dateFormat]="dateFormat"
        >
        </h-chat-message-system>

        <h-chat-message-voice-note
          *ngSwitchCase="MessageType.VoiceNote"
          [url]="message"
          [files]="files"
          [sender]="sender"
          [sendAt]="sendAt"
          [dateFormat]="dateFormat"
        >
        </h-chat-message-voice-note>

        <h-chat-message-file
          *ngSwitchDefault
          [message]="message"
          [files]="files"
          [sender]="sender"
          [sendAt]="sendAt"
          [dateFormat]="dateFormat"
        >
        </h-chat-message-file>

        <!--<h-chat-message-quote
          *ngSwitchCase="'quote'"
          [sender]="sender"
          [sendAt]="sendAt"
          [dateFormat]="dateFormat"
          [message]="message"
          [quote]="quote"
        >
        </h-chat-message-quote>

        <h-chat-message-map
          *ngSwitchCase="'map'"
          [message]="message"
          [latitude]="latitude"
          [longitude]="longitude"
          [sender]="sender"
          [sendAt]="sendAt"
        >
        </h-chat-message-map>-->
      </ng-container>
    </div>
  `,
  animations: [
    trigger("flyInOut", [
      state("in", style({ transform: "translateX(0)" })),
      transition("void => *", [style({ transform: "translateX(-100%)" }), animate(80)]),
      transition("* => void", [animate(80, style({ transform: "translateX(100%)" }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  readonly MessageType = MessageType;

  @HostBinding("@flyInOut")
  get flyInOut(): boolean {
    return true;
  }

  @HostBinding("class.not-reply")
  get notReply(): boolean {
    return !this.reply;
  }

  /** Determines if a message is a reply */
  @HostBinding("class.reply")
  get reply(): boolean {
    return this.userId === this.senderId;
  }

  avatarStyle: SafeStyle;

  /** Message sender avatar */
  @Input()
  set avatar(value: string) {
    this.avatarStyle = value
      ? this._domSanitizer.bypassSecurityTrustStyle(`url(${value})`)
      : null;
  }

  /** Message type, available options `text|system|file|map|quote` */
  @Input() type: MessageType;

  /** Current user Id */
  @Input() userId: string;

  /** Message sender */
  @Input() message: string;

  /** Message sender Id */
  @Input() senderId: string;

  /** Message sender */
  @Input() sender: string;

  /** Message send date */
  @Input() sendAt: Date;

  /** Message send date format, default 'shortTime' */
  @Input() dateFormat: string;

  /** Array of files */
  @Input() files?: string[];

  /** Quoted message text */
  @Input() quote: string;

  /** Map latitude */
  @Input() latitude: number;

  /** Map longitude */
  @Input() longitude: number;

  constructor(private readonly _domSanitizer: DomSanitizer) {}

  getInitials(): string {
    if (this.sender) {
      const names = this.sender.split(" ");

      return names
        .map((n) => n.charAt(0))
        .splice(0, 2)
        .join("")
        .toUpperCase();
    }

    return "";
  }
}
