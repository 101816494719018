import { Enum, Enums } from "./enum.type";
import { Experience } from "@core/api/api-client";

export const Experiences = new Enums([
  new Enum({
    key: Experience.Entry,
    name: "exp.entry",
    route: ["entry"],
    color: "purple-600",
  }),
  new Enum({
    key: Experience.Professional,
    name: "exp.pro",
    route: ["professional"],
    color: "purple-600",
  }),
  new Enum({
    key: Experience.Expert,
    name: "exp.expert",
    route: ["expert"],
    color: "purple-600",
  }),
]);
