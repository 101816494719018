import { Enum, Enums } from "./enum.type";
import { Industry } from "@core/api/api-client";

export const Industries = new Enums([
  new Enum({
    key: Industry.Technology,
    name: "industry.tech",
    route: ["technology"],
    color: "purple-500",
  }),
  new Enum({
    key: Industry.ECommerce,
    name: "industry.ecommerce",
    route: ["ecommerce"],
    color: "deep-purple-400",
  }),
  new Enum({
    key: Industry.Education,
    name: "industry.edu",
    route: ["education"],
    color: "indigo-400",
  }),
  new Enum({
    key: Industry.Medical,
    name: "industry.medical",
    route: ["medical"],
    color: "light-blue-400",
  }),
  new Enum({
    key: Industry.Ngo,
    name: "industry.ngo",
    route: ["ngo"],
    color: "blue-gray-400",
  }),
  new Enum({
    key: Industry.Industrial,
    name: "industry.industrial",
    route: ["industrial"],
    color: "blue-400",
  }),
  new Enum({
    key: Industry.Fashion,
    name: "industry.fashion",
    route: ["fashion"],
    color: "green-400",
  }),
  new Enum({
    key: Industry.FitnessAndLifestyle,
    name: "industry.lifestyle",
    route: ["fitness-lifestyle"],
    color: "amber-500",
  }),
  new Enum({
    key: Industry.Travel,
    name: "industry.travel",
    route: ["travel"],
    color: "teal-400",
  }),
  new Enum({
    key: Industry.FoodAndBeverage,
    name: "industry.beverage",
    route: ["food-beverage"],
    color: "cyan-400",
  }),
  new Enum({
    key: Industry.Entertainment,
    name: "industry.entertainment",
    route: ["entertainment"],
    color: "brown-400",
  }),
  new Enum({
    key: Industry.AdvertisingAndMediaProduction,
    name: "industry.ad",
    route: ["advertising-media-production"],
    color: "red-400",
  }),
  new Enum({
    key: Industry.Others,
    name: "industry.other",
    route: ["other"],
    color: "deep-orange-400",
  }),
]);
