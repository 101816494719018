import { Category, Industry, Language, NameVm, UserType } from "@core/api/api-client";
import { ExternalLogin, ITokenPayload, Login, Role } from "@core/auth/models/token";
import { isString, toBoolean } from "@core/utils";

/** Current user. */
export class User {
  id: string;
  userName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneVerified: boolean;
  created?: Date;
  lang?: Language;
  name?: NameVm;
  lastName?: string;
  picture?: string;
  freelancerId?: string;
  cat?: Category;
  clientId?: string;
  industry?: Industry;
  roles?: Role[];
  logins?: Login[];
  type?: UserType;

  haveRole: boolean;
  haveAccount: boolean;
  isClient: boolean;
  isFreelancer: boolean;
  isAdmin: boolean;

  constructor(payload: ITokenPayload) {
    this.id = payload.sub;
    this.userName = payload.name;
    this.email = payload.email;
    this.emailVerified = toBoolean(payload.email_verified);
    this.phoneNumber = payload.phone_number;
    this.phoneVerified = toBoolean(payload.phone_number_verified);
    this.created = new Date(payload.created);
    this.picture = payload.picture || "assets/img/user-placeholder.png";
    this.freelancerId = payload.freelancer_id;
    this.cat = payload.cat;
    this.clientId = payload.client_id;
    this.industry = payload.industry;
    this.type = payload.type;
    this.lang = payload.lang;

    this.haveAccount = !!payload.first_name || !!payload.last_name;
    this.name = this.haveAccount
      ? { firstName: payload.first_name, lastName: payload.last_name }
      : null;
    this.roles = isString(payload.roles)
      ? [payload.roles as Role]
      : (payload.roles as Role[]);
    this.logins = isString(payload.logins)
      ? [payload.logins as Login]
      : (payload.logins as Login[]);

    this.haveRole = !!this.roles && this.roles.length > 0;
    this.isAdmin = this.haveRole && this.roles.includes("Admin");
    this.isClient = this.clientId && this.clientId.length > 0;
    this.isFreelancer = this.freelancerId && this.freelancerId.length > 0;
  }

  private _externalLogins: ExternalLogin[] = null;
  get externalLogins(): ExternalLogin[] {
    if (this._externalLogins) {
      return this._externalLogins;
    }

    return this.logins.filter((l) => l !== "Cred") as ExternalLogin[];
  }

  private _available: ExternalLogin[] = null;

  get availableExternalLogins(): ExternalLogin[] {
    if (this._available) {
      return this._available;
    }

    if (!this.externalLogins) {
      this._available = ["Facebook", "Google"];
      return this._available;
    }

    this._available = [];
    if (!this.externalLogins.find((e) => e === "Google")) {
      this._available.push("Google");
    }

    if (!this.externalLogins.find((e) => e === "Facebook")) {
      this._available.push("Facebook");
    }

    return this._available;
  }

  private _havePassword: boolean | null = null;
  get havePassword(): boolean {
    if (this._havePassword) {
      return this._havePassword;
    }

    return this.logins.includes("Cred");
  }
}
