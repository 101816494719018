import { inject, InjectionToken } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Lang } from "./settings/settings.types";

export const LANGUAGE = new InjectionToken<Observable<Lang>>("Language observable", {
  providedIn: "root",
  factory: () => {
    const translate = inject(TranslateService);

    return translate.onLangChange.pipe(
      map((v) => v.lang as Lang),
      startWith(translate.currentLang as Lang)
    );
  },
});
