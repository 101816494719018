import { AuthTokenNotFoundError } from "./auth-errors";
import { decodeJwtPayload } from "./token-decode-helpers";

export type Role = "Client" | "Freelancer" | "Admin";
export type ExternalLogin = "Google" | "Facebook";
export type Login = "Cred" | ExternalLogin;

export interface ITokenPayload {
  sub?: string;
  name?: string;
  email?: string;
  email_verified?: boolean;
  phone_number?: string;
  phone_number_verified?: boolean;
  type?: number;
  lang?: number;
  created?: string;
  first_name?: string;
  last_name?: string;
  picture?: string;
  freelancer_id?: string;
  cat?: number;
  client_id?: string;
  industry?: number;
  roles?: Role | Role[];
  logins?: Login | Login[];
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
}

export class AuthToken {
  private _payload: ITokenPayload = null;
  private readonly _token: string = null;
  private readonly _issuedAt?: number;
  private readonly _expireAt?: number;

  constructor(token: string) {
    this._token = token;

    try {
      this._parsePayload();
    } catch (err) {
      // token is present but has got a problem, including illegal
      if (!(err instanceof AuthTokenNotFoundError)) {
        throw err;
      }
    }

    const decoded = this._payload;
    this._issuedAt = decoded && decoded.iat ? Number(decoded.iat) * 1000 : null;
    this._expireAt = decoded && decoded.exp ? Number(decoded.exp) * 1000 : null;
  }

  /** Get the token payload */
  get payload(): ITokenPayload {
    return this._payload;
  }

  /** Validate value and convert to string, if value is not valid return empty string */
  get value(): string | null {
    return !!this._token ? this._token : null;
  }

  /** Is data expired */
  get isValid(): boolean {
    if (!this._token || !this._expireAt) {
      return false;
    }

    return Date.now() < this._expireAt;
  }

  /** Returns token expiration date in milliseconds since the unix epoch. */
  get issuedAt(): number | null {
    return this._issuedAt;
  }

  /** Returns token expiration date in milliseconds since the unix epoch. */
  get expireAt(): number | null {
    return this._expireAt;
  }

  private _parsePayload(): void {
    if (!this._token) {
      this._payload = null;
      throw new AuthTokenNotFoundError("Token not found. ");
    }

    this._payload = decodeJwtPayload(this._token);
  }
}
