import { Enum, Enums } from "@models/enums/enum.type";
import { WorkEnvironment } from "@core/api/api-client";

export const WorkEnvironments = new Enums([
  new Enum({
    key: WorkEnvironment.Online,
    name: "work_env.online",
    route: ["online"],
    color: "purple-500",
  }),
  new Enum({
    key: WorkEnvironment.Offline,
    name: "work_env.offline",
    route: ["offline"],
    color: "gray-500",
  }),
  new Enum({
    key: WorkEnvironment.Both,
    name: "work_env.both",
    route: ["both"],
    color: "purple-400",
  }),
]);
