import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
  selector: "h-chat-message-files-preview",
  template: `<ng-container *ngIf="readyFiles?.length > 1">
      <div class="message-content-group">
        <a
          *ngFor="let file of readyFiles"
          [href]="file.url"
          target="_blank"
          rel="noopener"
        >
          <mat-icon [svgIcon]="'attach_file'" *ngIf="!file.bg"></mat-icon>
          <div *ngIf="file.bg" [style.background-image]="file.bg"></div>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="readyFiles?.length === 1">
      <a [href]="readyFiles[0].url" target="_blank" rel="noopener">
        <mat-icon *ngIf="!readyFiles[0].bg" [svgIcon]="'attach_file'"></mat-icon>
        <div *ngIf="readyFiles[0].bg" [style.background-image]="readyFiles[0].bg"></div>
      </a>
    </ng-container>`,
})
export class ChatMessageFilesPreviewComponent {
  readyFiles: { isImage: boolean; url: string; bg: SafeStyle }[];

  /** Message file path */
  @Input() set files(files: string[] | undefined) {
    this.readyFiles = (files || []).map((file) => {
      const isImage = this.isImage(file);
      return {
        isImage,
        url: file,
        bg: isImage && this._domSanitizer.bypassSecurityTrustStyle(`url(${file})`),
      };
    });

    this._cd.detectChanges();
  }

  constructor(
    protected readonly _cd: ChangeDetectorRef,
    protected readonly _domSanitizer: DomSanitizer
  ) {}

  isImage(file: string): boolean {
    return (
      file.endsWith(".png") ||
      file.endsWith(".gif") ||
      file.endsWith(".jpeg") ||
      file.endsWith(".jpg")
    );
  }
}
