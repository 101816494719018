import { OptionsDialogComponent } from "@shared/dialogs/options-dialog/options-dialog.component";
import { IDialogOptionsButtons } from "@shared/dialogs/options-dialog/option-dialog.interface";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { ModalDialogComponent, ConfirmDialogComponent } from "@shared/dialogs";
import { TranslateService } from "@ngx-translate/core";
import { ISnackBarParam, SnackBarVariant } from "./snackbar.types";
import { getLangDir } from "@core/services/settings/utils";
import { Lang } from "@core/services/settings/settings.types";

function _getSnackbarPanelClass(variant: SnackBarVariant): string[] | undefined {
  return variant === "success"
    ? ["snack-success"]
    : variant === "error"
    ? ["snack-error"]
    : undefined;
}

@Injectable({ providedIn: "root" })
export class ModalService {
  constructor(
    private readonly _dialog: MatDialog,
    private readonly _snackBar: MatSnackBar,
    private readonly _translate: TranslateService
  ) {}

  alertDialog(
    message: string,
    title: string = null,
    toDisableClose = true,
    toCloseOnNavigation = true
  ): void {
    const _title = title || this._translate.instant("services.modal.alertDialogTitle");

    this._dialog.open(ModalDialogComponent, {
      data: { title: _title, message },
      width: "450px",
      direction: getLangDir(this._translate.currentLang as Lang),
      disableClose: toDisableClose,
      closeOnNavigation: toCloseOnNavigation,
    });
  }

  confirmDialog(message: string, title = "Confirm?"): Observable<boolean> {
    const _title = title || this._translate.instant("services.modal.confirmDialogTitle");

    const ref = this._dialog.open(ConfirmDialogComponent, {
      data: { title: _title, message },
      width: "500px",
      direction: getLangDir(this._translate.currentLang as Lang),
      closeOnNavigation: true,
    });

    return ref.afterClosed();
  }

  optionsDialog(
    message: string,
    title: string,
    options: IDialogOptionsButtons[]
  ): Observable<number> {
    const ref = this._dialog.open(OptionsDialogComponent, {
      data: { title, message, options },
      width: "500px",
      direction: getLangDir(this._translate.currentLang as Lang),
      closeOnNavigation: true,
    });

    return ref.afterClosed();
  }

  snackbar(message: string, variant?: SnackBarVariant, duration = 10000): void {
    this._snackBar.open(message, this._translate.instant("services.modal.dismiss"), {
      direction: getLangDir(this._translate.currentLang as Lang),
      duration,
      panelClass: _getSnackbarPanelClass(variant),
    });
  }

  /** Show normal snackbar */
  snackbarInLocal({ token, interpolateParams, duration, variant }: ISnackBarParam): void {
    this._snackBar.open(
      this._translate.instant(token, interpolateParams),
      this._translate.instant("services.modal.dismiss"),
      {
        direction: getLangDir(this._translate.currentLang as Lang),
        duration: duration || 10000,
        panelClass: _getSnackbarPanelClass(variant),
      }
    );
  }
}
