import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

/** Chat message component. */
@Component({
  selector: "h-chat-message-voice-note",
  template: `
    <audio controls>
      <source [src]="url" type="audio/webm" />
    </audio>

    <h-chat-message-files-preview [files]="files"></h-chat-message-files-preview>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageVoiceNoteComponent {
  /** Message sender */
  @Input() sender: string;

  /** Message send date */
  @Input() sendAt: Date;

  /** Message send date format, default 'shortTime' */
  @Input() dateFormat = "shortTime";

  /** Message files path */
  @Input() files: string[];

  /** voice note file path */
  @Input() url: string;
}
