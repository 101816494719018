import { Enum, Enums } from "@models/enums/enum.type";
import { Currency } from "@core/api/api-client";

export const Currencies = new Enums([
  new Enum({
    key: Currency.EgyptianPound,
    name: "currency.egp",
    route: ["egp"],
    color: "blue-400",
  }),
  new Enum({
    key: Currency.UsDollar,
    name: "currency.usd",
    route: ["usd"],
    color: "green-700",
  }),
]);
