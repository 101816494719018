import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ChatMessageComponent } from "./chat-message.component";

/**
 * Main features:
 * - different message types support (text, image, file, file group, map, etc)
 * - drag & drop for images and files with preview
 *
 * Basic chat configuration and usage:
 *
 * ```ts
 * <h-chat title="Chat">
 *   <h-chat-message
 *     *ngFor="let msg of messages"
 *     [type]="msg.type"
 *     [message]="msg.text"
 *     [reply]="msg.reply"
 *     [sender]="msg.user.name"
 *     [date]="msg.date"
 *     [files]="msg.files"
 *     [quote]="msg.quote"
 *     [latitude]="msg.latitude"
 *     [longitude]="msg.longitude"
 *     [avatar]="msg.user.avatar">
 *   </h-chat-message>
 *
 *   <h-chat-form (send)="sendMessage($event)" [dropFiles]="true">
 *   </h-chat-form>
 * </h-chat>
 * ```
 *
 * ### Installation
 *
 * Import `ChatModule` to your feature module.
 * ```ts
 * @NgModule({
 *   imports: [
 *     // ...
 *     ChatModule,
 *   ],
 * })
 * export class PageModule { }
 * ```
 *
 * If you need to provide an API key for a `map` message type (which is required by Google Maps)
 * you may use `ChatModule.forRoot({ ... })` call if this is a global app configuration
 * or `ChatModule.forChild({ ... })` for a feature module configuration:
 *
 * ```ts
 * @NgModule({
 *   imports: [
 *     // ...
 *     ChatModule.forRoot({ messageGoogleMapKey: 'MAP_KEY' }),
 *   ],
 * })
 * export class AppModule { }
 * ```
 *
 * ### Usage
 *
 * There are three main components:
 * ```ts
 * <h-chat>
 * </h-chat> // chat container
 *
 * <h-chat-form>
 * </h-chat-form> // chat form with drag&drop files feature
 *
 * <h-chat-message>
 * </h-chat-message> // chat message, available multiple types
 * ```
 * ---
 * Based on: Nebular Chat UI
 */
@Component({
  selector: "h-chat",
  styleUrls: ["./chat.component.scss"],
  template: `
    <div class="header" *ngIf="title">{{ title }}</div>
    <div class="scrollable" #scrollable>
      <div class="messages">
        <ng-content select="h-chat-message"></ng-content>
        <p class="no-messages" *ngIf="!messages?.length">{{ noMessagesPlaceholder }}</p>
      </div>
    </div>
    <div class="form">
      <ng-content select="h-chat-form"></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements AfterViewInit {
  @Input() title: string;

  /** Chat size, available sizes:`tiny`, `small`, `medium`, `large`, `giant` */
  @Input() size: "tiny" | "small" | "medium" | "large" | "giant";

  @Input() noMessagesPlaceholder = "No messages yet.";

  @Output() loadMessages = new EventEmitter<void>();

  @HostBinding("class.size-tiny")
  get tiny(): boolean {
    return this.size === "tiny";
  }

  @HostBinding("class.size-small")
  get small(): boolean {
    return this.size === "small";
  }

  @HostBinding("class.size-medium")
  get medium(): boolean {
    return this.size === "medium";
  }

  @HostBinding("class.size-large")
  get large(): boolean {
    return this.size === "large";
  }

  @HostBinding("class.size-giant")
  get giant(): boolean {
    return this.size === "giant";
  }

  @ViewChild("scrollable") scrollable: ElementRef;
  @ContentChildren(ChatMessageComponent) messages: QueryList<ChatMessageComponent>;

  ngAfterViewInit(): void {
    this.messages.changes.subscribe((messages) => {
      this.messages = messages;

      // TODO: only when send a new message or initial load not when load old messages
      setTimeout(() => this.scrollListBottom());
    });

    this.scrollable.nativeElement.onscroll = (e) => {
      if (e.target.scrollTop < 10) {
        this.loadMessages.next();
      }
    };
  }

  scrollListBottom(): void {
    this.scrollable.nativeElement.scrollTop = this.scrollable.nativeElement.scrollHeight;
  }
}
