import { Enum, Enums } from "./enum.type";
import { TimeCommitment } from "@core/api/api-client";

export const TimeCommitments = new Enums([
  new Enum({
    key: TimeCommitment.Freelance,
    name: "job_commitment.freelance",
    route: ["freelance"],
    color: "purple-400",
  }),
  new Enum({
    key: TimeCommitment.RemotelyFullTime,
    name: "job_commitment.full",
    route: ["full-time"],
    color: "purple-600",
  }),
  new Enum({
    key: TimeCommitment.RemotelyPartTime,
    name: "job_commitment.part",
    route: ["part-time"],
    color: "purple-700",
  }),
]);
