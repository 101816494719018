import { Enum, Enums } from "@models/enums/enum.type";
import { RateRange } from "@core/api/api-client";

export const RateRanges = new Enums([
  new Enum({
    key: RateRange.From0To50,
    name: "rate_range.50",
    route: ["below-50"],
    color: "cyan-500",
  }),
  new Enum({
    key: RateRange.From50To100,
    name: "rate_range.50_100",
    route: ["0-50"],
    color: "purple-500",
  }),
  new Enum({
    key: RateRange.From100To150,
    name: "rate_range.100_150",
    route: ["100-150"],
    color: "deep-purple-500",
  }),
  new Enum({
    key: RateRange.From150ToAbove,
    name: "rate_range.150",
    route: ["above-150"],
    color: "indigo-400",
  }),
]);
