import {
  ChatMemberVm,
  ChatType,
  ChatVm,
  MessageStatus,
  MessageType,
  MessageVm,
} from "@core/api/api-client";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { distinct, distinctUntilChanged } from "rxjs/operators";
import { ChatMessage } from "@core/services";

export type ChatMember = ChatMemberVm;

export class Chat {
  constructor(id: number, type: ChatType, unread: number, members: ChatMember[]) {
    this.id = id;
    this.type = type;
    this._unread$ = new BehaviorSubject<number>(unread);
    this._members = members;
    members.forEach((m) => this._members$.next(m));
  }

  static from(chat: ChatVm): Chat {
    return new Chat(chat.id, chat.type, chat.unread, chat.members);
  }

  private readonly _unread$: BehaviorSubject<number>;
  private readonly _new$ = new ReplaySubject<ChatMessage>();
  private readonly _new = new Array<ChatMessage>();
  private readonly _loaded$ = new ReplaySubject<ChatMessage>();
  private readonly _loaded = new Array<ChatMessage>();
  private readonly _members$ = new ReplaySubject<ChatMember>();

  private readonly _members: ChatMember[];
  private _isLoaded = false;
  private _hasMore = true;

  readonly id: number;
  readonly type: ChatType;

  get hasMore(): boolean {
    return this._hasMore;
  }

  get isLoaded(): boolean {
    return this._isLoaded;
  }

  get new$(): Observable<Readonly<ChatMessage>> {
    return this._new$.pipe(distinctUntilChanged((x, y) => x.id == y.id));
  }

  get loaded$(): Observable<Readonly<ChatMessage>> {
    return this._loaded$.pipe(distinctUntilChanged((x, y) => x.id == y.id));
  }

  get unread$(): Observable<number> {
    return this._unread$.pipe(distinctUntilChanged());
  }

  get members(): Readonly<ChatMember[]> {
    return this._members;
  }

  get members$(): Observable<Readonly<ChatMember>> {
    return this._members$.pipe(distinct((i) => i.id));
  }

  load(messages: MessageVm[]): void {
    if (messages.length === 0) {
      this._hasMore = false;
      return;
    }

    if (this._isLoaded) {
      messages.reverse().forEach((m) => this._loadOne(m));
    }

    messages.reverse().forEach((m) => {
      if (!this._new.find((i) => i.id == m.id)) {
        this._loadOne(m);
      }
    });

    this._isLoaded = true;
  }

  private _loadOne(m: MessageVm): void {
    const message = this.toMessage(m);
    this._loaded.unshift(message);
    this._loaded$.next(message);
  }

  join(member: ChatMember): void {
    if (this.type !== ChatType.Support) {
      throw new Error("Can join only support chats");
    }

    this._members.push(member);
    this._members$.next(member);
  }

  read(accountId: string): void {
    this._new
      .filter((m) => m.sender.id !== accountId)
      .forEach((m) => {
        m.readAt = new Date();
        m.status = MessageStatus.Read;
      });

    this._unread$.next(0);
  }

  send(message: ChatMessage): void {
    this._new.push(message);
    this._new$.next(message);
  }

  receive(message: ChatMessage): void {
    this._new.push(message);
    this._new$.next(message);
    this._unread$.next(this._unread$.value + 1);
  }

  firstLoaded(): ChatMessage | undefined {
    if (!this._isLoaded) {
      return undefined;
    }

    return this._loaded[0];
  }

  toMessage(message: MessageVm): ChatMessage {
    const m = message as ChatMessage;

    m.sender =
      message.type === MessageType.System
        ? null
        : this._members.find((m) => m.id == message.sendBy) ?? null;

    return m;
  }
}
