import {
  DataLayerService,
  RealTimeService,
  SeoService,
  DynamicMenuService,
  SettingsService,
  LazyScriptService,
  LocalStorageService,
  MetaDataService,
  Intercom,
  ChatManager,
  AudioRecorder,
} from "./services";
import {
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import {
  AdminGuard,
  AuthorizeGuard,
  IdentityManager,
  ClientGuard,
  FreelancerGuard,
  UnauthenticatedGuard,
  AuthTokenService,
  OpenPostJobGuard,
} from "./auth";
import { ApiErrorHandlerService } from "./api";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MatIconRegistry } from "@angular/material/icon";
import { EnsureModuleLoadedOnceGuard } from "./loaded-once.guard";
import { ErrorHandlerService } from "./error.handler";
import { HttpInterceptors as HttpInterceptorsProviders } from "@core/interceptors";
import { CustomIconRegistry, SvgIconsProviders } from "./icons";
import { LOGGER_PROVIDER as LoggerProviders } from "@core/services/loggers";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChatModule } from "@widgets/chat/chat.module";
import { ToastrModule } from "ngx-toastr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { InitializerProviders } from "./bootstrap/initializers";

const GUARDS = [
  AuthorizeGuard,
  UnauthenticatedGuard,
  AdminGuard,
  ClientGuard,
  FreelancerGuard,
  OpenPostJobGuard,
];

function TranslateHttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "assets/", ".json");
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ChatModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      easeTime: 1000,
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
    }),
  ],
  exports: [HttpClientModule],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see
  // if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...InitializerProviders,
        ...HttpInterceptorsProviders,
        ...SvgIconsProviders,
        ...LoggerProviders,
        ...GUARDS,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: MatIconRegistry, useClass: CustomIconRegistry },
        // SessionStorageService,
        MetaDataService,
        LocalStorageService,
        AuthTokenService,
        ApiErrorHandlerService,
        ChatManager,
        // GoogleAnalyticsService,
        AudioRecorder,
        DataLayerService,
        RealTimeService,
        IdentityManager,
        SeoService,
        LazyScriptService,
        DynamicMenuService,
        SettingsService,
        // Intercom,
      ],
    };
  }
}
