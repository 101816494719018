import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Injectable()
export class MetaDataService {
  constructor(
    @Inject(PLATFORM_ID) readonly platformId,
    @Inject(DOCUMENT) private readonly _dom: Document,
    private readonly _title: Title
  ) {
    this.#isBrowser = isPlatformBrowser(platformId);
  }

  readonly #isBrowser: boolean;
  readonly default = {
    title: "Best Freelancers & Remote Employees for Hire in Egypt - elharefa",
    desc: "Hire top freelancers in Egypt online on elharefa Platform, Ready to work on your project. Browse the top vetted & more experienced freelancers profiles.",
  };

  get title(): string {
    return this._title.getTitle();
  }

  set title(title: string) {
    if (!this.#isBrowser) {
      return;
    }

    this._changeTitle(title || this.default.title);
  }

  get desc(): string | undefined {
    return this._dom
      .querySelectorAll("head > meta[name='Description']")[0]
      ?.getAttribute("content")
      .valueOf();
  }

  set desc(desc: string) {
    if (!this.#isBrowser) {
      return;
    }

    this._changeDescription(desc || "");
  }

  reset(): void {
    this._changeTitle(this.default.title);
    this._changeDescription(this.default.desc);
  }

  private _changeTitle(title: string): void {
    this._title.setTitle(title);

    this._dom
      .querySelectorAll("head > meta[name='Title']")
      .forEach((el) => el.setAttribute("content", title));

    this._dom
      .querySelectorAll("head > meta[name='twitter:title']")
      .forEach((el) => el.setAttribute("content", title));

    this._dom
      .querySelectorAll("head > meta[property='og:title']")
      .forEach((el) => el.setAttribute("content", title));
  }

  private _changeDescription(desc: string): void {
    this._dom
      .querySelectorAll("head > meta[name='Description']")
      .forEach((el) => el.setAttribute("content", desc));

    this._dom
      .querySelectorAll("head > meta[name='twitter:description']")
      .forEach((el) => el.setAttribute("content", desc));

    this._dom
      .querySelectorAll("head > meta[property='og:description']")
      .forEach((el) => el.setAttribute("content", desc));
  }
}
