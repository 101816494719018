import { Enum, Enums } from "./enum.type";
import { ProposalStatus } from "@core/api/api-client";

export const ProposalStates = new Enums([
  new Enum({
    key: ProposalStatus.Pending,
    name: "proposal_state.pending",
    route: ["pending"],
    color: "yellow-800",
  }),
  new Enum({
    key: ProposalStatus.Rejected,
    name: "proposal_state.rejected",
    route: ["rejected"],
    color: "red-400",
  }),
  new Enum({
    key: ProposalStatus.Accepted,
    name: "proposal_state.accepted",
    route: ["accepted"],
    color: "green-400",
  }),
  new Enum({
    key: ProposalStatus.Archived,
    name: "proposal_state.arch",
    route: ["archived"],
    color: "gray-500",
  }),
]);
