import { AbstractControl } from "@angular/forms";

export function convertToBoolProperty(val: any): boolean {
  if (typeof val === "string") {
    val = val.toLowerCase().trim();

    return val === "true" || val === "";
  }

  return !!val;
}

/** Returns a valid HTML id from @str */
export function generateId(str: string): string {
  return String(str)
    .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "") // Remove HTML tags
    .replace(/[^A-Za-z -]/g, "") // Remove not valid characters
    .trim()
    .replace(/\s+/g, "-") // Replace space with characters
    .toLowerCase();
}

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

/**
 * Return true if the type of the value is string, otherwise returns false.
 * @param val value to be checked
 */
export function isString(val: any): boolean {
  return val && (typeof val === "string" || val instanceof String);
}

export function toBoolean(val: any): boolean {
  if (isString(val)) {
    val = val.toLowerCase().trim();

    return val === "true";
  }

  return !!val;
}

export function serialize(obj = {}): string {
  const arr = [];
  for (const k of Object.keys(obj)) {
    arr.push(
      `${k}=${encodeURIComponent(
        typeof obj[k] === "string"
          ? String.prototype.trim.call(obj[k])
          : obj[k] === null
          ? ""
          : obj[k]
      )}`
    );
  }
  return arr.join("&");
}

/**
 * Get enum keys.
 */
export function getKeys(enumObj: unknown): string[] {
  return Object.values(enumObj)
    .filter((k) => isNaN(Number(enumObj[k])))
    .map((v) => enumObj[v]);
}

/**
 * Recursively delete keys that have value equal to null | undefined | '' from the object
 * or the own objects.
 * @param obj object to remove keys from.
 */
export function delEmptyKey(obj: {}): {} {
  const objCpy = {};
  if (obj === null || obj === undefined || obj === "") {
    return objCpy;
  }
  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] === "object") {
      objCpy[key] = this.delEmptyKey(obj[key]);
    } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      objCpy[key] = obj[key];
    }
  }
  return objCpy;
}

/**
 * Determine wither object | string | array is empty.
 */
export function isEmptyObject(obj: {}): boolean {
  let name: any;
  // tslint:disable-next-line: forin
  for (name in obj) {
    return false;
  }
  return true;
}

/**
 * is valid date helper
 */
export function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

/**
 * object to string
 */
export function obj2Str(obj: any): {} {
  const p = {};
  for (const key of Object.keys(obj)) {
    if (obj[key] || obj[key] === 0) {
      if (obj[key].toString() !== "") {
        p[key] = obj[key].toString();
      }
    }
  }
  return p;
}

export function str2arr(str: string): string[] {
  return str.replace(/[\r\n\s]/g, "").split(",");
}

export function getScrollbarWidth(): number {
  const scrollDiv = document.createElement("div");
  scrollDiv.style.cssText =
    "width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
}

export const isExternalUrl = (url: string): boolean => {
  const match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
  if (
    isString(match[1]) &&
    match[1].length > 0 &&
    match[1].toLowerCase() !== location.protocol
  ) {
    return true;
  }

  return (
    isString(match[2]) &&
    match[2].length > 0 &&
    match[2].replace(
      new RegExp(":(" + { "http:": 80, "https:": 443 }[location.protocol] + ")?$"),
      ""
    ) !== location.host
  );
};

/** Returns ture if URL is absolute, false otherwise. */
export const isAbsoluteUrl = (url: string): boolean => {
  return !!url.match(new RegExp("^(?:[a-z]+:)?//", "i"));
};

export const isSameOriginUrl = (url: string): boolean => {
  // It's an absolute url with the same origin.
  if (url.startsWith(`${window.location.origin}/`)) {
    return true;
  }

  // It's a protocol relative url with the same origin.
  // For example: //www.example.com/api/Products
  if (url.startsWith(`//${window.location.host}/`)) {
    return true;
  }

  // It's a relative url like /api/Products
  if (/^\/[^\/].*/.test(url)) {
    return true;
  }

  // It's an absolute or protocol relative url that
  // doesn't have the same origin.
  return false;
};

/** Group objects by a property */
export function groupBy<T>(list: Array<T>, keyGetter: (x: T) => any): Map<any, T[]> {
  const map = new Map<any, T[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/** Clear error form AbstractControl by its name. */
export const clearError = (control: AbstractControl, error: string): void => {
  const err = control?.errors;
  if (!err) {
    return;
  }
  delete err[error];
  if (!Object.keys(err).length) {
    control.setErrors(null);
  } else {
    control.setErrors(err);
  }
};

/** Get last element of array */
export function last<T>(arr: Array<T>): T | null {
  return arr && arr.length > 0 ? arr[arr.length - 1] : null;
}
