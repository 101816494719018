import { DataLayerService, SeoService, RealTimeService } from "@core/services";
import { IdentityManager } from "@core/auth";
import { Component, AfterViewInit, OnInit } from "@angular/core";

@Component({
  selector: "h-root",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private readonly _realtime: RealTimeService,
    private readonly _dataLayer: DataLayerService,
    private readonly _seo: SeoService,
    private readonly _identityManager: IdentityManager
  ) {}

  ngOnInit(): void {
    this._realtime.connect();
    this._dataLayer.pushUserState();
    this._dataLayer.trackPageViews();
    this._seo.trackCanonicalChanges();
  }

  ngAfterViewInit(): void {
    this._hidePreloader();
  }

  private _hidePreloader(): void {
    const el = document.getElementById("globalLoader");
    if (el) {
      el.addEventListener("transitionend", () => {
        el.className = "global-loader-hidden";
      });

      if (!el.className.includes("global-loader-hidden")) {
        el.className += " global-loader-fade-in";
      }
    }
  }
}
