<!-- Dropped Files -->
<div class="dropped-files" *ngIf="!!droppedFiles?.length">
  <ng-container *ngFor="let file of droppedFiles">
    <div *ngIf="file.previewUrl" [style.background-image]="file.previewUrl">
      <span class="remove" (click)="removeFile(file)">&times;</span>
    </div>

    <div *ngIf="!file.previewUrl">
      <mat-icon svgIcon="file-alt"></mat-icon>
      <span class="remove" (click)="removeFile(file)">&times;</span>
    </div>
  </ng-container>
</div>

<!-- Message Input -->
<div class="message-row">
  <textarea
    *ngIf="!isRecording; else recordTrack"
    class="message-row_track with-button"
    matInput
    rows="2"
    type="text"
    autocomplete="off"
    placeholder="{{ fileOver ? dropFilePlaceholder : messagePlaceholder }}"
    (focus)="onFocus()"
    (blur)="inputFocus = false"
    (keyup.enter)="sendMessage()"
    (mouseenter)="inputHover = true"
    (mouseleave)="inputHover = false"
    [(ngModel)]="message"
    disabled="{{!chatAvailable}}"
  ></textarea>

  <ng-template #recordTrack>
    <!-- TODO: counter is work while user not give the permission yet -->
    <!-- TODO: show need permission and wait to give -->
    <div class="message-row_track message-row_recorder">
      <span>{{ recordLength | timing }}</span>
      <button mat-button color="warn" (click)="clearRecord()">cancel</button>
    </div>
  </ng-template>

  <div>
    <!-- Attachments -->
    <input
      #input
      multiple
      type="file"
      style="display: none"
      (change)="addFiles(input.files)"
    />
    <button disabled="{{!chatAvailable}}" (click)="input.click()" class="message-row_btn" mat-icon-button>
      <mat-icon svgIcon="attach_file"></mat-icon>
    </button>

    <!-- Send Text -->
    <button
      disabled="{{!chatAvailable}}"
      *ngIf="message || droppedFiles.length > 0; else voice"
      (click)="sendMessage()"
      class="message-row_btn with-icon"
      mat-icon-button
    >
      <mat-icon [color]="getColor()" [svgIcon]="buttonIcon"></mat-icon>
    </button>

    <!-- Send Voice Note -->
    <ng-template #voice>
      <button
        disabled="{{!chatAvailable}}"
        (click)="isRecording ? sendRecord() : startRecord()"
        class="message-row_btn with-icon"
        mat-icon-button
      >
        <mat-icon
          [color]="getColor()"
          [svgIcon]="isRecording ? 'send' : 'mic'"
        ></mat-icon>
      </button>
    </ng-template>
  </div>
</div>
