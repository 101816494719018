import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IOptionDialog } from "./option-dialog.interface";

@Component({
  selector: "h-options-dialog",
  templateUrl: "./options-dialog.component.html",
})
export class OptionsDialogComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<OptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: IOptionDialog
  ) {}
}
