import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { SeverityLevel } from "../services/loggers/severity-level";

export function debug<T>(message: string, level: SeverityLevel = SeverityLevel.Verbose) {
  return (source: Observable<T>) =>
    source.pipe(
      tap((val) => {
        if (environment.production && level < SeverityLevel.Information) {
          return;
        }

        console.log(message + ": ", val);
      })
    );
}
