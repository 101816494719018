import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { IdentityManager } from "../services/identity-manager.service";
import { PATHS } from "@models";

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {
  constructor(private _identityManager: IdentityManager, private _router: Router) {}

  canLoad(_route: Route): Observable<boolean> {
    return this._handle();
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._handle();
  }

  private _handle(): Observable<boolean> {
    return this._identityManager.user$.pipe(
      take(1),
      map((user) => {
        if (user && user.isAdmin) {
          return true;
        }

        this._router.navigateByUrl(PATHS.Error404);
        return false;
      })
    );
  }
}
