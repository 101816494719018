import { Enum, Enums } from "@models/enums/enum.type";
import { UserType } from "@core/api/api-client";

export const UserTypes = new Enums([
  new Enum({
    key: UserType.Client,
    name: "user_type.client",
    route: ["client"],
  }),
  new Enum({
    key: UserType.Freelancer,
    name: "user_type.Freelancer",
    route: ["freelancer"],
  }),
  new Enum({
    key: UserType.Team,
    name: "user_type.team",
    route: ["team"],
  }),
]);
