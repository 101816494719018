import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { IdentityManager } from "../services/identity-manager.service";
import { PATHS, QUERY_PARAMETER_NAMES } from "@models";

@Injectable()
export class AuthorizeGuard implements CanActivate, CanLoad {
  constructor(private _identityManager: IdentityManager, private _router: Router) {}

  canLoad(_route: Route, segments: UrlSegment[]): Observable<boolean> {
    const returnUrl = segments.reduce(
      (path, currentSegment) => `${path}/${currentSegment.path}`,
      ""
    );

    return this._handle(returnUrl);
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._handle(state.url);
  }

  private _handle(returnUrl: string): Observable<boolean> {
    return this._identityManager.isAuthenticated$.pipe(
      take(1),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this._router.navigate([PATHS.Login], {
            queryParams: {
              [QUERY_PARAMETER_NAMES.ReturnUrl]: !!returnUrl ? returnUrl : null,
            },
          });

          return false;
        }

        return true;
      })
    );
  }
}
