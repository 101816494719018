import {
  IdentityClient,
  CredentialLoginCommand,
  ConfirmEmailChangeCommand,
  ConfirmEmailCommand,
  ResetPasswordCommand,
  LoginProvider,
} from "@core/api/api-client";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthTokenService } from "./token.service";
import { AuthToken } from "../models/token";
import { User } from "../models/user";
import { LocalStorageService } from "@core/services/storage/local-storage.service";

export interface ExternalProviderUser {
  provider: LoginProvider;
  token: string;
  email?: string;
  photoUrl?: string;
  firstName?: string;
  lastName?: string;
}

/** Common authentication & authorization service. */
@Injectable()
export class IdentityManager {
  constructor(
    private readonly _identityClient: IdentityClient,
    private readonly _tokenSrv: AuthTokenService,
    private readonly _localStorage: LocalStorageService
  ) {}

  /** Get changes in user$ token */
  get user$(): Observable<User | null> {
    return this._tokenSrv.token$.pipe(
      map((token) =>
        token && token.isValid && token.payload ? new User(token.payload) : null
      )
    );
  }

  get user(): User | null {
    return this._tokenSrv.token?.payload ? new User(this._tokenSrv.token.payload) : null;
  }

  /** Returns authentication status stream */
  get isAuthenticated$(): Observable<boolean> {
    return this._tokenSrv.token$.pipe(map((token) => token && token.isValid));
  }

  /** Returns current authentication status */
  get isAuthenticated(): boolean {
    return !!this._tokenSrv.token?.isValid;
  }

  login(req: CredentialLoginCommand): Observable<User> {
    return this._processToken(this._identityClient.login(req));
  }

  logout(): void {
    this._tokenSrv.clear();
  }

  /** Refresh current user token if it valid. */
  refreshToken(): Observable<User | null> {
    if (!this.isAuthenticated) {
      return of(null);
    }
    return this._processToken(this._identityClient.refreshToken());
  }

  providerLogin(req: ExternalProviderUser): Observable<User> {
    return this._processToken(this._identityClient.providerLogin(req));
  }

  storeProviderInfo(req: ExternalProviderUser): void {
    this._localStorage.set("EXTERNAL_PROVIDER_DATA", req);
  }

  getProviderInfo(): ExternalProviderUser {
    return this._localStorage.get("EXTERNAL_PROVIDER_DATA") as ExternalProviderUser;
  }

  clearStoredProvider(): void {
    this._localStorage.remove("EXTERNAL_PROVIDER_DATA");
  }

  resetPassword(req: ResetPasswordCommand): Observable<User> {
    return this._processToken(this._identityClient.resetPassword(req));
  }

  confirmEmail(req: ConfirmEmailCommand): Observable<User> {
    return this._processToken(this._identityClient.confirmEmail(req));
  }

  confirmEmailChange(req: ConfirmEmailChangeCommand): Observable<User> {
    return this._processToken(this._identityClient.confirmEmailChange(req));
  }

  processToken(token: string): User {
    const authToken = new AuthToken(token);
    this._tokenSrv.set(authToken);

    return new User(authToken.payload);
  }

  private _processToken(observable: Observable<string>): Observable<User> {
    return observable.pipe(map((token) => this.processToken(token)));
  }
}
