import { Enum, Enums } from "@models/enums/enum.type";
import { JobDuration } from "@core/api/api-client";

export const JobDurations = new Enums([
  new Enum({
    key: JobDuration.Immediately,
    name: "job_duration.imm",
    route: ["immediately"],
    color: "purple-500",
  }),
  new Enum({
    key: JobDuration.Less1Month,
    name: "job_duration.month",
    route: ["less-1"],
    color: "purple-500",
  }),
  new Enum({
    key: JobDuration.From1MonthTo3,
    name: "job_duration.1_3",
    route: ["1-3"],
    color: "purple-500",
  }),
  new Enum({
    key: JobDuration.From3MonthsTo6,
    name: "job_duration.3_6",
    route: ["3-6"],
    color: "purple-500",
  }),
  new Enum({
    key: JobDuration.MoreThan6Months,
    name: "job_duration.6",
    route: ["above-6"],
    color: "purple-500",
  }),
]);
