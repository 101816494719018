import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

/** Chat message component. */
@Component({
  selector: "h-chat-message-text",
  template: `
    <p class="sender" *ngIf="sender || sendAt">
      <!-- <span [matTooltip]="sender" matTooltipPosition="left">{{ sender }}</span>-->
      <time>{{ sendAt | date: dateFormat }}</time>
    </p>
    <p class="text" *ngIf="message" dir="auto">{{ message }}</p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageTextComponent {
  readyFiles: any[];

  /** Message sender */
  @Input() sender: string;

  /** Message sent */
  @Input() message: string;

  /** Message send date */
  @Input() sendAt: Date;

  /** Message send date format, default 'shortTime' */
  @Input() dateFormat = "shortTime";
}
