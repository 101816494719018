import { Observable, throwError, timer } from "rxjs";
import { finalize, mergeMap } from "rxjs/operators";

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 10,
    scalingDuration = 2000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find((e) => e === error.status)
        ) {
          return throwError(error);
        }
        console.log(
          `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
        );
        // retry after 2s, 4s, etc...
        return timer(retryAttempt * scalingDuration);
      }),
      finalize(() => console.log("We are done!"))
    );
  };
