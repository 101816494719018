import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { filter } from "rxjs/operators";

declare const ga: any;

@Injectable()
export class GoogleAnalyticsService {
  #enabled: boolean;

  constructor(private readonly _location: Location, private readonly _router: Router) {
    this.#enabled = false;
  }

  trackPageViews(): void {
    if (this.#enabled) {
      this._router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          ga("send", { hitType: "pageview", page: this._location.path() });
        });
    }
  }

  trackEvent(eventName: string): void {
    if (this.#enabled) {
      ga("send", "event", eventName);
    }
  }
}
