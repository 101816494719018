import { Enum, Enums } from "./enum.type";
import { Category } from "@core/api/api-client";

export const Categories = new Enums([
  new Enum({
    key: Category.Developers,
    name: "cat.dev",
    route: ["developers"],
    color: "indigo-600",
    icon: "phonelink",
  }),
  new Enum({
    key: Category.Designers,
    name: "cat.designers",
    route: ["designers"],
    color: "deep-purple-400",
    icon: "palette",
  }),
  new Enum({
    key: Category.Translators,
    name: "cat.translators",
    route: ["translators"],
    color: "pink-600",
    icon: "translate",
  }),
  new Enum({
    key: Category.MediaProduction,
    name: "cat.media",
    route: ["media-production"],
    color: "light-green-600",
    icon: "movie",
  }),
  new Enum({
    key: Category.DigitalMarketing,
    name: "cat.dig_marketing",
    route: ["digital-marketing"],
    color: "light-blue-600",
    icon: "share",
  }),
  new Enum({
    key: Category.Writing,
    name: "cat.writing",
    route: ["writing"],
    color: "cyan-600",
    icon: "edit",
  }),
  new Enum({
    key: Category.Marketing,
    name: "cat.marketing",
    route: ["marketing"],
    color: "amber-600",
    icon: "storefront",
  }),
  new Enum({
    key: Category.Sales,
    name: "cat.sales",
    route: ["sales"],
    color: "brown-600",
    icon: "monetization-on",
  }),
  new Enum({
    key: Category.Training,
    name: "cat.training",
    route: ["training"],
    color: "deep-orange-600",
    icon: "school",
  }),
  new Enum({
    key: Category.Finance,
    name: "cat.finance",
    route: ["finance"],
    color: "lime-600",
    icon: "bar-chart",
  }),
  new Enum({
    key: Category.Consulting,
    name: "cat.consulting",
    route: ["business", "consulting"],
    color: "blue-600",
    icon: "contact-support",
  }),
  new Enum({
    key: Category.AdministrativeService,
    name: "cat.admin",
    route: ["business", "administrative-services"],
    color: "green-600",
    icon: "account-balance-wallet",
  }),
  new Enum({
    key: Category.Hr,
    name: "cat.hr",
    route: ["business", "hr"],
    color: "yellow-600",
    icon: "contacts",
  }),
  new Enum({
    key: Category.Engineering,
    name: "cat.engineering",
    route: ["engineers", "engineering"],
    color: "blue-gray-600",
    icon: "square-foot",
  }),
  new Enum({
    key: Category.Architecture,
    name: "cat.arch",
    route: ["engineers", "architecture"],
    color: "gray-600",
    icon: "apartment",
  }),
]);
