import { Enum, Enums } from "@models/enums/enum.type";
import { NotificationReceiver } from "@core/api/api-client";

export const NotificationReceivers = new Enums([
  new Enum({
    key: NotificationReceiver.ByEmails,
    name: "notification_receiver.emails",
    route: ["emails"],
    color: "indigo-600",
    icon: "send",
  }),
  new Enum({
    key: NotificationReceiver.Freelancer,
    name: "notification_receiver.freelancers",
    route: ["freelancer"],
    color: "indigo-200",
    icon: "send",
  }),
  new Enum({
    key: NotificationReceiver.Clients,
    name: "notification_receiver.clients",
    route: ["clients"],
    color: "indigo-600",
    icon: "send",
  }),
  new Enum({
    key: NotificationReceiver.Both,
    name: "notification_receiver.f_c",
    route: ["both"],
    color: "indigo-600",
    icon: "send",
  }),
  new Enum({
    key: NotificationReceiver.AllUsers,
    name: "notification_receiver.all",
    route: ["all"],
    color: "indigo-600",
    icon: "send",
  }),
  new Enum({
    key: NotificationReceiver.Team,
    name: "notification_receiver.team",
    route: ["team"],
    color: "indigo-600",
    icon: "send",
  }),
]);
