import { Enum, Enums } from "./enum.type";
import { ClientType } from "@core/api/api-client";

export const ClientTypes = new Enums([
  new Enum({
    key: ClientType.Company,
    name: "client_type.com",
    route: ["company"],
    color: "blue-400",
  }),
  new Enum({
    key: ClientType.Individual,
    name: "client_type.ind",
    route: ["individual"],
    color: "gray-400",
  }),
]);
