import { Enum, Enums } from "./enum.type";
import { JobType } from "@core/api/api-client";

export const JobTypes = new Enums([
  new Enum({
    key: JobType.MonthlyProject,
    name: "job_type.monthly",
    route: ["monthly"],
    color: "deep-purple-400",
  }),
  new Enum({
    key: JobType.OneTimeProject,
    name: "job_type.one_time",
    route: ["one"],
    color: "purple-400",
  }),
]);
