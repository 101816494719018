import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@shared";
import { ChatOptions } from "./chat.options";
import { ChatComponent } from "./chat.component";
import { ChatMessageComponent } from "./chat-message.component";
import { ChatFormComponent } from "./chat-form.component";
import { ChatMessageTextComponent } from "./messages/chat-message-text.component";
import { ChatMessageFileComponent } from "./messages/chat-message-file.component";
import { ChatMessageQuoteComponent } from "./messages/chat-message-quote.component";
import { ChatMessageMapComponent } from "./messages/chat-message-map.component";
import { ChatMessageSystemComponent } from "./messages/chat-message-system.component";
import { ChatMessageVoiceNoteComponent } from "./messages/chat-message-voice-note.component";
import { ChatMessageFilesPreviewComponent } from "./messages/chat-message-files-preview.component";

const CHAT_COMPONENTS = [
  ChatComponent,
  ChatMessageComponent,
  ChatFormComponent,
  ChatMessageTextComponent,
  ChatMessageFileComponent,
  ChatMessageQuoteComponent,
  ChatMessageMapComponent,
  ChatMessageSystemComponent,
  ChatMessageVoiceNoteComponent,
  ChatMessageFilesPreviewComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: [...CHAT_COMPONENTS],
  exports: [...CHAT_COMPONENTS],
})
export class ChatModule {
  static forRoot(options?: ChatOptions): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [{ provide: ChatOptions, useValue: options || {} }],
    };
  }

  static forChild(options?: ChatOptions): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [{ provide: ChatOptions, useValue: options || {} }],
    };
  }
}
