import { Enum, Enums } from "./enum.type";
import { WorkingAsStatus } from "@core/api/api-client";

export const WorkAsStates = new Enums([
  new Enum({
    key: WorkingAsStatus.TotallyFreelancer,
    name: "work_as.freelancer",
    route: ["totally-freelancer"],
    color: "blue-400",
  }),
  new Enum({
    key: WorkingAsStatus.TotallyFullTime,
    name: "work_as.full",
    route: ["totally-full-time"],
    color: "red-400",
  }),
  new Enum({
    key: WorkingAsStatus.FullTimeFreelancer,
    name: "work_as.full_freelancer",
    route: ["full-time-freelancer"],
    color: "deep-purple-400",
  }),
  new Enum({
    key: WorkingAsStatus.PartTimeFreelancer,
    name: "work_as.part_freelancer",
    route: ["part-time-freelancer"],
    color: "purple-400",
  }),
]);
