import { Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Categories, Industries } from "@models";
import { IdentityManager } from "@core/auth/services/identity-manager.service";

declare const dataLayer: Array<any>;

@Injectable()
export class DataLayerService implements OnDestroy {
  readonly #enabled = true;
  readonly #destroy$ = new Subject<void>();

  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _identityManager: IdentityManager
  ) {}

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  pushUserState(): void {
    if (!this.#enabled) {
      return;
    }

    this._identityManager.user$.pipe(takeUntil(this.#destroy$)).subscribe((user) => {
      if (!user) {
        dataLayer.push({ userLoginState: false });
        return;
      }

      const cat = Categories.get(user.cat);
      const industry = Industries.get(+user.industry);

      dataLayer.push({
        userLoginState: true,
        userFirstName: user.name?.firstName,
        userLastName: user.name?.lastName,
        userEmail: user.email,
        userName: user.userName,
        userId: user.id,
        phoneNumber: user.phoneNumber,
        cat: cat?.name,
        industry: industry?.name,
        userRegistrationDate: user.created,
        roles: user.roles,
      });
    });
  }

  trackPageViews(): void {
    if (this.#enabled) {
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          takeUntil(this.#destroy$)
        )
        .subscribe(() => {
          dataLayer.push({
            event: "Pageview",
            pagePath: this._location.path(),
            pageTitle: "elharefa Platform", // TODO: update this after implement the page title.
          });
        });
    }
  }

  trackEvent(event: string, extras?: {}): void {
    if (this.#enabled) {
      dataLayer.push({ event, extras });
    }
  }
}
