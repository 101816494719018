import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable, Inject, Optional } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { PATHS } from "@models";
import { environment } from "@env/environment";
import { StatusCodes } from "@core/api";
import { isSameOriginUrl, isAbsoluteUrl } from "@core/utils";
import { AuthTokenService } from "@core/auth/services/token.service";
import { SettingsService } from "@core/services/settings/settings.service";
import { ModalService } from "@core/services/modals/modal.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject("APP_VERSION") private readonly _version: string,
    private readonly _router: Router,
    private readonly _tokenSrv: AuthTokenService,
    private readonly _modal: ModalService,
    private readonly _settings: SettingsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isAbsoluteUrl(req.url)) {
      return next.handle(req);
    }

    // Only intercept same origin & api requests
    const reqUrl = new URL(req.url.toLowerCase());
    const apiUrl = new URL(environment.apiUrl.toLowerCase());

    if (reqUrl.hostname !== apiUrl.hostname && !isSameOriginUrl(req.url)) {
      return next.handle(req);
    }

    const headers: any = {
      "Version": this._version,
      "Accept-Language": this._settings.options.language,
      "Authorization": `Bearer ${this._tokenSrv.token?.value || ""}`,
    };

    req = req.clone({ setHeaders: headers });

    return next.handle(req).pipe(catchError((err) => this._handleErrorReq(err)));
  }

  private _handleErrorReq(err: HttpErrorResponse): Observable<never> {
    switch (err.status) {
      case StatusCodes.Unauthorized:
        this._tokenSrv.clear();
        setTimeout(() => this._router.navigateByUrl(PATHS.Login));
        break;

      case StatusCodes.InternalError:
        setTimeout(() => this._router.navigateByUrl(PATHS.Error500));
        break;

      case StatusCodes.BadRequest:
        setTimeout(() =>
          this._modal.snackbarInLocal({ token: "errors.unsupported_version" })
        );
        break;
    }

    return throwError(err);
  }
}
