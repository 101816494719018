import { Enum, Enums } from "@models/enums/enum.type";
import { FollowUpStatus } from "@core/api/api-client";

export const FollowupStates = new Enums([
  new Enum({
    key: FollowUpStatus.Hold,
    name: "follow_state.hold",
    route: ["hold"],
    color: "gray-600",
  }),
  new Enum({
    key: FollowUpStatus.InProcess,
    name: "follow_state.in_processing",
    route: ["processing"],
    color: "yellow-600",
  }),
  new Enum({
    key: FollowUpStatus.ClosedLost,
    name: "follow_state.lost",
    route: ["lost"],
    color: "red-600",
  }),
  new Enum({
    key: FollowUpStatus.ClosedWin,
    name: "follow_state.success",
    route: ["win"],
    color: "green-400",
  }),
]);
