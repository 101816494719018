import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Menu, MenuChildrenItem } from "./menu.model";

@Injectable()
export class DynamicMenuService {
  private menu$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  /** Menu level */

  private static isLeafItem(item: MenuChildrenItem): boolean {
    // if a menuItem is leaf
    const cond0 = item.route === undefined;
    const cond1 = item.children === undefined;
    const cond2 = !cond1 && item.children.length === 0;
    return cond0 || cond1 || cond2;
  }

  private static deepCopyJsonObj(jsonObj: any): any {
    return JSON.parse(JSON.stringify(jsonObj));
  }

  private static jsonObjEqual(jobj0: any, jobj1: any): boolean {
    // if two objects-could-be-json-ized equal
    return JSON.stringify(jobj0) === JSON.stringify(jobj1);
  }

  private static routeEqual(
    routeArr: Array<string>,
    realRouteArr: Array<string>
  ): boolean {
    //// if routeArr equals realRouteArr(after remove empty-route-element)
    realRouteArr = DynamicMenuService.deepCopyJsonObj(realRouteArr);
    realRouteArr = realRouteArr.filter((r) => r !== "");
    return DynamicMenuService.jsonObjEqual(routeArr, realRouteArr);
  }

  getAll(): Observable<Menu[]> {
    return this.menu$.asObservable();
  }

  set(menu: Menu[]): Observable<Menu[]> {
    this.menu$.next(menu);
    return this.menu$.asObservable();
  }

  add(menu: Menu): void {
    const tmpMenu = this.menu$.value;
    tmpMenu.push(menu);
    this.menu$.next(tmpMenu);
  }

  reset(): void {
    this.menu$.next([]);
  }

  getMenuItemName(routeArr: string[]): string {
    return this.getMenuLevel(routeArr)[routeArr.length - 1];
  }

  getMenuLevel(routeArr: string[]): string[] {
    let tmpArr = [];
    this.menu$.value.forEach((item) => {
      //// breadth-first-traverse -modified
      let unhandledLayer = [{ item, parentNamePathList: [], realRouteArr: [] }];
      while (unhandledLayer.length > 0) {
        let nextUnhandledLayer = [];
        for (const ele of unhandledLayer) {
          const eachItem = ele.item;
          const currentNamePathList = DynamicMenuService.deepCopyJsonObj(
            ele.parentNamePathList
          ).concat(eachItem.name);
          const currentRealRouteArr = DynamicMenuService.deepCopyJsonObj(
            ele.realRouteArr
          ).concat(eachItem.route);
          //// compare the full Array
          //// for expandable
          const cond = DynamicMenuService.routeEqual(routeArr, currentRealRouteArr);
          if (cond) {
            tmpArr = currentNamePathList;
            break;
          }
          ////
          const isLeafCond = DynamicMenuService.isLeafItem(eachItem as MenuChildrenItem);
          if (!isLeafCond) {
            const children = eachItem.children;
            const wrappedChildren = children.map((child) => ({
              item: child,
              parentNamePathList: currentNamePathList,
              realRouteArr: currentRealRouteArr,
            }));
            nextUnhandledLayer = nextUnhandledLayer.concat(wrappedChildren);
          }
        }
        unhandledLayer = nextUnhandledLayer;
      }
    });
    return tmpArr;
  }

  /** Menu for translation */

  recursMenuForTranslation(menu: Menu[] | MenuChildrenItem[], namespace: string): void {
    menu.forEach((menuItem) => {
      menuItem.name = `${namespace}.${menuItem.name}`;
      if (menuItem.children && menuItem.children.length > 0) {
        this.recursMenuForTranslation(menuItem.children, menuItem.name);
      }
    });
  }
}
