import { Pipe, PipeTransform } from "@angular/core";
import { Currency } from "@core/api/api-client";
import { budgetToString } from "@core/utils";
import { TranslateService } from "@ngx-translate/core";
import { Currencies } from "@models";

@Pipe({ name: "budgetToString" })
export class BudgetToStringPipe implements PipeTransform {
  constructor(private readonly _translate: TranslateService) {}

  transform([from, to, currency]: [number, number, Currency]): string {
    const curr = this._translate.instant(Currencies.get(currency).name);
    return budgetToString(from, to, curr);
  }
}
